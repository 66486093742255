import React, { useEffect } from "react";
import DemoResultCard from "../../components/DemoResultCard/DemoResultCard";
import PageDescription from "../../components/PageDescription/PageDescription";
import UploadImage from "../../components/UploadImage/UploadImage";
import { dashboardContent } from "../../helpers/dashboardContent";
import useDataLayer from "../../helpers/StateProvider";

import "./Demo.css";

const Demo = () => {
  const [{ user }, dispatch] = useDataLayer();

  useEffect(() => {
    dispatch({
      type: "SET_DEMO_IMAGE",
      demoImage: null,
    });
  }, []);

  let pathName = window.location.pathname;
  pathName = pathName.substring(1);
  const paths = pathName.split("/");

  return (
    <div className="demo">
      <PageDescription
        title={dashboardContent[paths[1]].demoTitle}
        subTitle={dashboardContent[paths[1]].demoSubtitle}
      />
      <UploadImage />
      <DemoResultCard />
    </div>
  );
};

export default Demo;
