import React from "react";
import ProductAdmin from "../../../components/ProductAdmin/ProductAdmin";

import "./Product.css";

const Product = () => {
  return (
    <div className="product">
      <ProductAdmin />
    </div>
  );
};

export default Product;
