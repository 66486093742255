import React from "react";
import sidebarContent from "../../helpers/sidebarContent";

//Style
import "./SideBar.css";

// Components
import SideBarOption from "../SideBarOption/SideBarOption";

// Assets
import { BsFileBarGraph } from "react-icons/bs";
import { BiSupport } from "react-icons/bi";
import logo from "../../assets/images/logo.webp";
import useDataLayer from "../../helpers/StateProvider";

const SideBar = ({ className }) => {
  const [{ user }, dispatch] = useDataLayer();

  const role = user?.user?.role;

  return (
    <div className={`sidebar ${className}`}>
      <div className="sidebar__head">
        {/* <BsFileBarGraph className="sidebar__head-icon" />
        <h2>SpoofSense</h2> */}
        <img
          src={logo}
          alt="spoofsense_logo"
          className="sidebar__head-logo-img"
        />
      </div>
      <div className="sidebar__body">
        {sidebarContent.map((option, index) => (
          <SideBarOption
            key={index}
            Icon={option.icon}
            text={option.text}
            route={option.route}
            subNav={option.subNav}
            IconUp={option.iconUp}
            IconDown={option.iconDown}
            forAdmin={option.forAdmin}
            forProduct={option.forProduct}
            role={role}
          />
        ))}
      </div>
      <div className="sidebar__foot">
        <a
          href="https://mail.google.com/mail/?view=cm&fs=1&tf=1&to=kartikeya@spoofsense.com"
          target="_blank"
          rel="noreferrer"
        >
          <BiSupport style={{ fontSize: "1.1rem", marginRight: ".3rem" }} />
          <p>Support</p>
        </a>
      </div>
    </div>
  );
};

export default SideBar;
