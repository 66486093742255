import React from "react";
import "./TopBanner.css";

const TopBanner = () => {
  return (
    <section className="topbanner">
      <a
        target="__blank"
        href="https://blog.google/intl/en-in/introducing-the-eighth-cohort-of-google-for-startups-accelerator-india/"
      >
        🚀 SpoofSense.ai Joins Google's AI Accelerator Program! Read More
      </a>
    </section>
  );
};

export default TopBanner;
