import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { signinFormvalidate } from "../../helpers/validation";

// Components
import MobileIllustration from "../../containers/MobileIllustration/MobileIllustration";

// Styles
import "./SignIn.css";

// Assets
import { BsFileBarGraph } from "react-icons/bs";
import { BiShow, BiHide } from "react-icons/bi";
import { FiLoader } from "react-icons/fi";
import logo from "../../assets/images/logo.webp";
import authService from "../../services/auth-service";
import useDataLayer from "../../helpers/StateProvider";
import { BeatLoader } from "react-spinners";

const SignIn = () => {
  const [signinFormValues, setSigninFormValues] = useState({
    email: "",
    password: "",
  });
  const [signinFormErrors, setSigninFormErrors] = useState({});
  const [signinIsSubmit, setSigninIsSubmit] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [{ user }, dispatch] = useDataLayer();

  const navigate = useNavigate();

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const signinInputHandler = (event) => {
    const { name, value } = event.target;
    setSigninFormValues({ ...signinFormValues, [name]: value });
  };

  const signinSubmitHandler = (event) => {
    event.preventDefault();
    setSigninFormErrors(signinFormvalidate(signinFormValues));
    setSigninIsSubmit(true);
  };

  useEffect(() => {
    const role = user?.user?.role;
    user
      ? role === "admin"
        ? navigate("/manageusers")
        : navigate("/products")
      : navigate("/signin");
  }, [user]);

  useEffect(() => {
    const setUser = async () => {
      if (!Object.keys(signinFormErrors).length && signinIsSubmit) {
        setIsLoading(true);
        const success = await authService.signin(signinFormValues);
        setIsLoading(false);
        success && window.location.reload();
      }
    };
    setUser();
  }, [signinFormErrors, signinIsSubmit]);

  return (
    <div className="signin">
      <div className="signin__form">
        <div className="signin__form-logo">
          {/* <BsFileBarGraph className="signin__form-logo-icon" />
          <h2>SpoofSense</h2> */}
          <img
            src={logo}
            alt="spoofsense_logo"
            className="signin__form-logo-img"
          />
        </div>

        <div className="signin__form-body">
          <div className="signin__form-body__head">
            <h1 className="signin__form-body__head-title">Sign In</h1>
            <h4 className="signin__form-body__head-subtitle">
              Sign in to SpoofSense
            </h4>
          </div>
          <form
            onSubmit={signinSubmitHandler}
            className="signin__form-body__main"
          >
            <div className="signin__form__input-container">
              <label className="signin_input-text" htmlFor="email">
                Enter your Email
              </label>
              <input
                className={`signin__input-box ${
                  signinFormErrors.email && "signin__input-error"
                }`}
                placeholder="name@example.com"
                type="email"
                name="email"
                value={signinFormValues.email}
                onChange={signinInputHandler}
              />
              <p className="signin__form-error">{signinFormErrors?.email}</p>
            </div>
            <div className="signin__form__input-container">
              <label className="signin_input-text" htmlFor="password">
                Enter your Password~
              </label>
              <input
                className={`signin__input-box signin__pwd__input-box ${
                  signinFormErrors.password && "signin__input-error"
                }`}
                placeholder="atleast 8 characters"
                type={showPassword ? "text" : "password"}
                name="password"
                value={signinFormValues.password}
                onChange={signinInputHandler}
              />
              <p className="signin__form-error">{signinFormErrors?.password}</p>
              {showPassword ? (
                <BiShow
                  onClick={toggleShowPassword}
                  className="signin__pwd-eye"
                />
              ) : (
                <BiHide
                  onClick={toggleShowPassword}
                  className="signin__pwd-eye"
                />
              )}
            </div>
            <button
              disabled={isLoading}
              type="submit"
              className="signin__form__btn"
            >
              {isLoading ? <BeatLoader size={6} color="white" /> : "Sign In"}
            </button>
          </form>
          <div className="signin__form-body__footer">
            <hr />
            <p>New User?</p>
            <button type="submit" onClick={() => navigate("/signup")}>
              Sign up Now
            </button>
          </div>
        </div>
      </div>

      <MobileIllustration />
    </div>
  );
};

export default SignIn;
