import img1Thumbnail from "../assets/images/blogs/passive-liveness-detection/img-1.jpg";
import img1 from "../assets/images/blogs/passive-liveness-detection/img-1.webp";
import { ABOUT_SPOOFSENSE, BLOT_CONTENT_UTIL_TYPES } from "./blogContentUtils";

const PASSIVE_LIVENESS_DETECTION = {
  title: "Passive Liveness Detection: A Beginner’s Guide",
  description:
    "Face recognition software has become a cornerstone of modern day biometrics, it is almost ubiquitous in modern day authentication methods.",
  thumbnail: img1Thumbnail,
  link: "passive-liveness-guide",
  minuteRead: "3",
  publishedDate: "April 17th, 2023",
  author: "Hritik Narayan",
  keywords: ["passive liveness", "facial liveness", "face antispoofing"],
  content: [
    {
      type: BLOT_CONTENT_UTIL_TYPES.IMAGE,
      source: [img1],
    },
    {
      type: BLOT_CONTENT_UTIL_TYPES.BLOCKQUOTE,
      source: [
        "How it works and why it is a better option than Active Liveness Detection",
      ],
    },
    {
      type: BLOT_CONTENT_UTIL_TYPES.HEADING,
      source: ["Overview"],
    },
    {
      type: BLOT_CONTENT_UTIL_TYPES.PARAGRAPH,
      source: [
        "Face recognition software has become a cornerstone of modern day biometrics, it is almost ubiquitous in modern day authentication methods. Facial recognition based biometrics are popular and widely used, but they also have glaring security flaws that attackers can exploit to spoof their way into your system, putting your data, customers and organisation at risk",
        "In this beginner’s guide, we will explore methods that are utilized to combat spoofing attacks, with a spotlight on Passive Liveness Detection. (for a general overview of Liveness Detection methods, <a href='https://www.spoofsense.ai/blogs/what-is-liveness-detection/' target='__blank'>refer here</a>",
      ],
    },
    {
      type: BLOT_CONTENT_UTIL_TYPES.HEADING,
      source: ["What is Passive Facial Liveness ?"],
    },
    {
      type: BLOT_CONTENT_UTIL_TYPES.PARAGRAPH,
      source: [
        "Passive Liveness Detection is a type of facial recognition technology that helps prevent spoofing attacks by verifying that the person in front of the camera is a real, living person, and not just a photograph, video, or mask. Unlike active liveness detection, which requires a person to perform specific actions, such as blinking or smiling, passive liveness detection can detect liveness without any active participation from the user",
      ],
    },
    {
      type: BLOT_CONTENT_UTIL_TYPES.BLOCKQUOTE,
      source: [
        "Passive Facial Liveness uses just a single frame to determine user liveness, which reduces friction and increases conversion.",
      ],
    },
    {
      type: BLOT_CONTENT_UTIL_TYPES.PARAGRAPH,
      source: [
        "Passive Liveness Detection works by analyzing various facial features to detect signs of life. For example, it can detect micro-expressions, changes in skin texture and color, and subtle image structures that are specific to human beings. It can also detect depth, which means that it can distinguish between a real person and a photograph or video.",
      ],
    },
    {
      type: BLOT_CONTENT_UTIL_TYPES.HEADING,
      source: ["Advantages of Passive Facial Liveness"],
    },
    {
      type: BLOT_CONTENT_UTIL_TYPES.PARAGRAPH,
      source: [
        "The benefits of passive liveness detection are numerous, including:",
      ],
    },
    {
      type: BLOT_CONTENT_UTIL_TYPES.LIST,
      source: [
        "Increased security: Passive liveness detection can help prevent spoofing attacks, which means that it can improve the overall security of any system that uses facial recognition technology. User-friendliness: Passive liveness detection doesn't require any active participation from the user, which means that it is more user-friendly than active liveness detection",
        "Better accuracy: Passive liveness detection can detect signs of life more accurately than active liveness detection, which means that it can reduce the number of false positives and false negatives.",
        "Faster TAT: The turn around time of passive liveness detection systems is significantly lesser than an active liveness system, thereby decreasing the user abandonment rate.",
      ],
    },
    {
      type: BLOT_CONTENT_UTIL_TYPES.PARAGRAPH,
      source: [
        "Passive Liveness Detection is effective at combating the most common forms of spoofing attacks, that are digital attacks (attacker holds up an image or video of a face in front of the biometric camera), printed attacks (attacker holds up a printed picture of a face in front of the biometric camera), and cut-out mask attacks (attacker wears a mask with cutouts for the eyes).",
      ],
    },
    ...ABOUT_SPOOFSENSE,
    {
      type: BLOT_CONTENT_UTIL_TYPES.HEADING,
      source: ["FAQs"],
    },
    {
      type: BLOT_CONTENT_UTIL_TYPES.HEADING,
      source: ["What is passive facial liveness?"],
    },
    {
      type: BLOT_CONTENT_UTIL_TYPES.PARAGRAPH,
      source: [
        "Passive facial liveness detection requires a single frame (selfie) to detect whether a user is ‘Live’ or a ‘Spoofing Attack’",
      ],
    },
    {
      type: BLOT_CONTENT_UTIL_TYPES.HEADING,
      source: ["How can it solve face spoofing?"],
    },
    {
      type: BLOT_CONTENT_UTIL_TYPES.PARAGRAPH,
      source: [
        "Passive facial liveness relies on micro-textures, depth, etc to differentiate between a Live user v/s a spoofing attack vector like a mask, photograph, etc.",
      ],
    },
    {
      type: BLOT_CONTENT_UTIL_TYPES.HEADING,
      source: ["How is it better than active liveness?"],
    },
    {
      type: BLOT_CONTENT_UTIL_TYPES.PARAGRAPH,
      source: [
        "Passive Liveness is fast, easy to integrate and very user-friendly.",
      ],
    },
  ],
};

export default PASSIVE_LIVENESS_DETECTION;
