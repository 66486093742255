export const dashboardContent = {
  facelivenessdetection: {
    demoTitle: "Spoofsense Passive Liveness API Demo",
    demoSubtitle:
      "Use the upload image button to test out the face passive liveness api",
    integrationTitle: "API Integration",
    integrationSubtitle:
      "Use the Sandbox Key and the code snippets to integrate SpoofSense Passive Liveness API in your backend",
    documentationLink:
      "https://spoofsense.gitbook.io/spoofsense-developer-documentation/",
    apiLink: process.env.REACT_APP_API_URL_FACESPOOF,
    productId: "62d659da9780a90b2817e9f6",
  },
};
