import React from "react";
import BlogPostContentHeader from "../BlogPostContentHeader/BlogPostContentHeader";
import "./BlogPostContent.css";
import { BLOT_CONTENT_UTIL_TYPES } from "../../../BlogContent/blogContentUtils";

const BlogPostContent = ({ blogInfo }) => {
  return (
    <div className="blogpostcontent">
      <BlogPostContentHeader blogInfo={blogInfo} />
      <div className="blogpagecontent__body">
        {blogInfo?.content?.map((item, index) => {
          switch (item.type) {
            case BLOT_CONTENT_UTIL_TYPES.HEADING:
              return item.source.map((heading, index) => (
                <h1 key={index} dangerouslySetInnerHTML={{ __html: heading }} />
              ));
            case BLOT_CONTENT_UTIL_TYPES.PARAGRAPH:
              return item.source.map((paragraph, index) => (
                <p
                  key={index}
                  dangerouslySetInnerHTML={{ __html: paragraph }}
                />
              ));
            case BLOT_CONTENT_UTIL_TYPES.BLOCKQUOTE:
              return item.source.map((quote, index) => (
                <div
                  key={index}
                  className="blockquote"
                  dangerouslySetInnerHTML={{ __html: `<p>${quote}</p>` }}
                />
              ));
            case BLOT_CONTENT_UTIL_TYPES.IMAGE:
              return item.source.map((image, index) => (
                <img key={index} src={image} alt={blogInfo.title} />
              ));
            case BLOT_CONTENT_UTIL_TYPES.LIST:
              return (
                <ol>
                  {item.source.map((listItem, index) => (
                    <li
                      key={index}
                      dangerouslySetInnerHTML={{ __html: listItem }}
                    />
                  ))}
                </ol>
              );
            default:
              break;
          }
        })}
        <br />
      </div>
    </div>
  );
};

export default BlogPostContent;
