import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  useNavigate,
} from "react-router-dom";

// Style
import "./Layout.css";

// Components
import SideBar from "../../components/SideBar/SideBar";
import TopBar from "../../components/TopBar/TopBar";
import useDataLayer from "../../helpers/StateProvider";
import Dashboard from "../../pages/Dashboard/Dashboard";

const Layout = ({ Page, route }) => {
  const [{ user }, dispatch] = useDataLayer();

  let navigate = useNavigate();
  useEffect(() => {
    user ? navigate("") : navigate("/signin");
  }, [user]);

  return (
    <div className="layout">
      <SideBar className="layout__sidebar" />
      <div className="layout__body">
        <TopBar />
        <div className="layout__content">
          <Page />
        </div>
      </div>
    </div>
  );
};

export default Layout;
