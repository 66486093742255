import React from "react";

import "./BlogPostSocial.css";

import { IoMail, IoLogoTwitter, IoLogoLinkedin } from "react-icons/io5";

import { IoIosArrowForward } from "react-icons/io";

const BlogPostSocial = () => {
  return (
    <div className="blogpostsocial">
      <div className="blogpostsocial__nav" style={{ visibility: "hidden" }}>
        <p>Next</p>
        <IoIosArrowForward size={18} />
      </div>
      <div className="blogpostsocial__details">
        <h4>Connect</h4>
        <div>
          <div>
            <IoMail size={20} />
            <a
              href="https://mail.google.com/mail/?view=cm&fs=1&tf=1&to=kartikeya@spoofsense.com"
              target="_blank"
              rel="noreferrer"
            >
              <p> Email Us</p>
            </a>
          </div>
          <div>
            <IoLogoTwitter size={20} />
            <a
              href="https://twitter.com/spoofsense"
              target="_blank"
              rel="noreferrer"
            >
              <p> Twitter</p>
            </a>
          </div>
          <div>
            <IoLogoLinkedin size={20} />
            <a
              href="https://www.linkedin.com/company/spoofsense"
              target="_blank"
              rel="noreferrer"
            >
              <p> LinkedIn</p>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlogPostSocial;
