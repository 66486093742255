import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import "./SideBarOption.css";
import { dashboardContent } from "../../helpers/dashboardContent";

const SideBarOption = ({
  Icon,
  text,
  route,
  subNav,
  IconUp,
  IconDown,
  forAdmin,
  forProduct,
  role,
}) => {
  const navigate = useNavigate();
  const [subnav, setSubnav] = useState(false);
  const { productId } = useParams();

  if ((forAdmin && role !== "admin") || (!forAdmin && role === "admin"))
    return null;

  if (window.location.pathname === "/products" && !forProduct) return null;
  if (window.location.pathname === "/settings" && !forProduct) return null;

  if (
    window.location.pathname !== "/products" &&
    window.location.pathname !== "/settings" &&
    (text === "Products" || text === "Settings")
  )
    return null;

  if (productId) {
    if (route) route = `/products/${productId}${route}`;
  }

  let pathName = window.location.pathname;
  pathName = pathName.substring(1);
  const paths = pathName.split("/");

  const navigateHandler = (route) => {
    if (route === "/documentation") {
      window.open(dashboardContent[paths[1]].documentationLink);
      return;
    } else if (route) {
      navigate(route);
    } else {
      subNav && setSubnav(!subnav);
    }
  };

  let active = false;
  if (route === window.location.pathname) active = true;
  return (
    <>
      <div
        className={`sidebaroption ${active && "sidebaroption--active"}`}
        onClick={() => navigateHandler(route)}
      >
        {/* <div className={`${active && "sidebaroption--active__left"}`}></div> */}
        <Icon className="sidebaroption__icon" />
        <h3>{text}</h3>
        <div className="sidebaroption__dropdown-icon">
          {subNav && subnav ? <IconUp /> : subNav ? <IconDown /> : null}
        </div>
      </div>
      {subnav &&
        subNav.map((item, index) => {
          return (
            <div
              key={index}
              className={`sidebaroption sidebaroption__subnav ${
                active && "sidebaroption--active"
              }`}
              onClick={() => navigateHandler(item.route)}
            >
              <div
                className={`${active && "sidebaroption--active__left"}`}
              ></div>
              <item.icon className="sidebaroption__icon" />
              <h3>{item.text}</h3>
            </div>
          );
        })}
    </>
  );
};

export default SideBarOption;
