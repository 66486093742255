import React from "react";
import ReactDOM from "react-dom";
import App from "./App";

import { QueryClientProvider, QueryClient } from "react-query";

import "./index.css";

import { reducer, initialState } from "./helpers/reducer";
import { StateProvider } from "./helpers/StateProvider";

const queryClient = new QueryClient();

ReactDOM.render(
  <QueryClientProvider client={queryClient}>
    <StateProvider reducer={reducer} initialState={initialState}>
      <App />
    </StateProvider>
  </QueryClientProvider>,
  document.getElementById("root")
);
