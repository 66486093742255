import React from "react";

import "./BlogCard.css";

import { AiOutlineArrowRight } from "react-icons/ai";
import { useNavigate } from "react-router-dom";

import { BsCalendar4Week, BsShare } from "react-icons/bs";
import { RiTimer2Line } from "react-icons/ri";
import { RWebShare } from "react-web-share";

const BlogCard = ({
  publishedDate,
  thumbnail,
  title,
  description,
  link,
  minuteRead,
  author,
}) => {
  const navigate = useNavigate();

  const currentURL = window.location.href;

  return (
    <div className="blogcard" onClick={() => navigate(`/blogs/${link}`)}>
      <img src={thumbnail} alt={title} />
      <div className="blogcard__content">
        <div className="blogcard__content-top">
          <img src={thumbnail} alt={title} />
          <h3>{title}</h3>
          <div>
            <h3>{title}</h3>
            <p>{description}</p>
            <p>{`By ${author}`}</p>
          </div>
        </div>

        <div className="blogcard__content-bottom">
          <hr />
          <div>
            <div>
              <div>
                <BsCalendar4Week size={16} />
                <p>{publishedDate}</p>
              </div>
              <div>
                <RiTimer2Line size={17} />
                <p
                  style={{ marginLeft: "-.2rem" }}
                >{`${minuteRead} min read`}</p>
              </div>
              <div className="share-btn">
                <RWebShare
                  data={{
                    text: description,
                    url: `${currentURL}/face-liveness-detection`,
                    title: title,
                  }}
                >
                  <div>
                    <BsShare size={15} />
                    <p>Share</p>
                  </div>
                </RWebShare>
              </div>
            </div>
            <div>
              <div>
                <p>Read More</p>
                <AiOutlineArrowRight />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlogCard;
