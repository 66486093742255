import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";

import "./AddNewProductModel.css";
import { MdOutlineCancel } from "react-icons/md";
import { editProductFormvalidate } from "../../helpers/validation";
import { BeatLoader } from "react-spinners";
import useDataLayer from "../../helpers/StateProvider";
import { addNewProduct } from "../../helpers/helpers";

const AddNewProductModal = (props) => {
  const [addNewProductFormValues, setAddNewProductFormValues] = useState({
    productName: "",
    documentationLink: "",
    description: "",
    apiEndpoint: "",
  });
  const [addNewProductFormErrors, setAddNewProductFormErrors] = useState({});
  const [addNewProductIsSubmit, setAddNewProductIsSubmit] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const addNewProductInputHandler = (event) => {
    const { name, value } = event.target;
    setAddNewProductFormValues({ ...addNewProductFormValues, [name]: value });
  };

  const signupSubmitHandler = (event) => {
    event.preventDefault();
    setAddNewProductFormErrors(
      editProductFormvalidate(addNewProductFormValues)
    );
    setAddNewProductIsSubmit(true);
  };

  const [{ user }, dispatch] = useDataLayer();

  useEffect(() => {
    const setProduct = async () => {
      if (
        !Object.keys(addNewProductFormErrors).length &&
        addNewProductIsSubmit
      ) {
        if (user) {
          const accessToken = user?.tokens?.access?.token;
          setIsLoading(true);
          const success = await addNewProduct(
            addNewProductFormValues,
            accessToken
          );
          success && window.location.reload();
          setIsLoading(false);
        }
      }
    };
    setProduct();
  }, [addNewProductFormErrors, addNewProductIsSubmit]);

  return ReactDOM.createPortal(
    <>
      <div className="addnewProductmodal__overlay"></div>
      <div className="addnewProductmodal">
        <MdOutlineCancel
          onClick={props?.close}
          className="addnewProductmodal__close"
        />
        <h1 className="addnewProductmodal__title">Add New Product</h1>
        <form
          onSubmit={signupSubmitHandler}
          className="addnewProductmodal__form"
          autoComplete="off"
        >
          <div className="addnewProductmodal__form-top">
            <div className="addnewProductmodal__form-container">
              <label htmlFor="productName">Product Name</label>
              <input
                value={addNewProductFormValues.productName}
                name="productName"
                type="text"
                onChange={addNewProductInputHandler}
              />
              <p className="addnewProductmodal__form-error">
                {addNewProductFormErrors?.productName}
              </p>
            </div>
            <div className="addnewProductmodal__form-container">
              <label htmlFor="documentationLink">Documentation Link</label>
              <input
                value={addNewProductFormValues.documentationLink}
                name="documentationLink"
                type="text"
                onChange={addNewProductInputHandler}
              />
              <p className="addnewProductmodal__form-error">
                {addNewProductFormErrors?.documentationLink}
              </p>
            </div>
          </div>
          <div className="addnewProductmodal__form-center">
            <div className="addnewProductmodal__form-container">
              <label htmlFor="description">Product Description</label>
              <input
                value={addNewProductFormValues.description}
                name="description"
                type="text"
                onChange={addNewProductInputHandler}
              />
              <p className="addnewProductmodal__form-error">
                {addNewProductFormErrors?.productDescription}
              </p>
            </div>
            <div className="addnewProductmodal__form-container">
              <label htmlFor="apiEndpoint">API Endpoint</label>
              <input
                value={addNewProductFormValues.apiEndpoint}
                name="apiEndpoint"
                type="text"
                onChange={addNewProductInputHandler}
              />
              <p className="addnewProductmodal__form-error">
                {addNewProductFormErrors?.apiEndpoint}
              </p>
            </div>
          </div>
          <button
            disabled={isLoading}
            type="submit"
            className="addnewProductmodal__addProduct-btn"
          >
            {isLoading ? <BeatLoader size={6} color="white" /> : "Add Product"}
          </button>
        </form>
      </div>
    </>,
    document.getElementById("portal")
  );
};

export default AddNewProductModal;
