import React from "react";

import "./BlogPostAuthor.css";

import { IoIosArrowBack } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import { capitalizeAndJoin } from "../../../BlogContent/blogContentUtils";

const BlogPostAuthor = ({ blogInfo }) => {
  let navigate = useNavigate();
  return (
    <div className="blogpostauthor">
      <div className="blogpostauthor__nav" onClick={() => navigate("/blogs")}>
        <IoIosArrowBack size={18} />
        <p>Back</p>
      </div>
      <div className="blogpostauthor__details">
        <div>
          <h4>Author</h4>
          <p>
            {" "}
            <span>{blogInfo.author}</span> <br /> <span></span>
          </p>
        </div>

        <div>
          <h4>Keywords</h4>
          <p>{capitalizeAndJoin(blogInfo.keywords)}</p>
        </div>

        {blogInfo.imageSources && (
          <div>
            <h4>Images</h4>

            <p>{capitalizeAndJoin(blogInfo.imageSources)}</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default BlogPostAuthor;
