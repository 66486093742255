import React, { useState } from "react";

import "./TopBar.css";

import { IoMdNotificationsOutline } from "react-icons/io";
import { FaUserCircle } from "react-icons/fa";
import { FiLoader } from "react-icons/fi";

import { useBreadcrumb } from "../../Hooks/Breadcrumb";
import authService from "../../services/auth-service";
import useDataLayer from "../../helpers/StateProvider";
import { BeatLoader } from "react-spinners";
import { useNavigate } from "react-router-dom";

const capitalizeFirstLetter = (string) =>
  string.charAt(0).toUpperCase() + string.slice(1);

const TopBar = () => {
  const { icon: Icon = FaUserCircle, text = "" } = useBreadcrumb();
  const [showNotification, setShowNotification] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();

  const [{ user }, dispatch] = useDataLayer();
  const name = `${user?.user?.firstName} ${user?.user?.lastName}`;
  const email = user?.user?.email;
  const refreshTokenJSON = { refreshToken: `${user?.tokens?.refresh?.token}` };

  let pathName = window.location.pathname;
  pathName = pathName.substring(1);
  const paths = pathName.split("/");

  const logout = async () => {
    setIsLoading(true);
    const success = await authService.logout(refreshTokenJSON);
    success && window.location.reload();
    setIsLoading(false);
  };

  return (
    <div className="topbar">
      <div className="topbar__left">
        <Icon className="topbar__left-icon" />
        <h3>
          {text === "Manage Users" || text === "Manage Products"
            ? "Admin Dashboard"
            : paths.map((path, index) => (
                <span
                  onClick={index === 0 ? () => navigate("/products") : () => {}}
                  key={index}
                  style={{
                    color:
                      index + 1 === paths.length
                        ? "var(--primary-color-dark)"
                        : "grey",
                    cursor:
                      index === 0
                        ? "pointer"
                        : index === 1
                        ? "not-allowed"
                        : "",
                  }}
                >
                  {`${capitalizeFirstLetter(path)} `}{" "}
                  {index + 1 !== paths.length && "> "}
                </span>
              ))}
        </h3>
      </div>
      <div className="topbar__right">
        <div className="topbar__right-notifications">
          <IoMdNotificationsOutline className="topbar__right-iconL" />
        </div>
        <div className="topbar__right-profile">
          <FaUserCircle
            onClick={() => setShowNotification(!showNotification)}
            className="topbar__right-iconR"
          />
          {showNotification && (
            <div className="topbar__right-profile_box">
              <FaUserCircle style={{ fontSize: "2.5rem" }} />
              <h5>{name}</h5>
              <p>{email}</p>
              <button disabled={isLoading} onClick={logout}>
                {isLoading ? <BeatLoader size={6} color="white" /> : "Logout"}
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default TopBar;
