export const initialState = {
  user: null,
  demoImage: null,
  demoLoading: false,
};

export const reducer = (state, action) => {
  switch (action.type) {
    case "SET_USER":
      return {
        ...state,
        user: action.user,
      };
    case "SET_DEMO_IMAGE":
      return {
        ...state,
        demoImage: action.demoImage,
      };
    case "SET_DEMO_LOADING":
      return {
        ...state,
        demoLoading: action.demoLoading,
      };
    case "SET_NEW_TOKENS":
      return {
        ...state,
        user: {
          ...state.user,
          tokens: action.tokens,
        },
      };
    case "SET_USER_DATA":
      return {
        ...state,
        user: {
          ...state.user,
          user: action.user,
        },
      };
    default:
      return state;
  }
};
