import faceLivenessDetectionThumbnail from "../assets/images/blogs/face liveness detection/img-1.webp";
import futureFaceImage from "../assets/images/blogs/face liveness detection/img-2.webp";
import { ABOUT_SPOOFSENSE, BLOT_CONTENT_UTIL_TYPES } from "./blogContentUtils";

const FACE_LIVENESS_DETECTION = {
  title: "Face Liveness Detection: An Essential Part of Face Recognition",
  description:
    "From unlocking phones to finding missing children, the world has adopted and used Face Recognition technology to solve various problems.",
  thumbnail: faceLivenessDetectionThumbnail,
  link: "face-liveness-detection",
  minuteRead: "2",
  publishedDate: "August 9th, 2022",
  author: "Kartikeya Bhardwaj",
  keywords: [
    "liveness detection",
    "face antispoofing",
    "customer onboarding",
    "kyc",
  ],
  imageSources: ["dalle.2"],
  content: [
    {
      type: BLOT_CONTENT_UTIL_TYPES.IMAGE,
      source: [faceLivenessDetectionThumbnail],
    },
    {
      type: BLOT_CONTENT_UTIL_TYPES.PARAGRAPH,
      source: [
        "From unlocking phones to finding missing children, the world has adopted and used Face Recognition technology to solve various problems.",
        "Thanks to the latest advancements in Deep Learning, Face Recognition algorithms have become incredibly accurate at matching faces, a consequence of which is its widespread adoption in use-cases like Customer Onboarding, VideoKYC, Verifying Dating Profiles, etc.",
        "However, it has its own array of loopholes.",
        "The biggest one is the problem of Face Spoofing. Face Spoofing is an attack used by Fraudsters that replicate the face of a target person (victim) by displaying presentations of the victim’s face through printed photos, digital displays, 3D silicone masks, etc.",
      ],
    },
    {
      type: BLOT_CONTENT_UTIL_TYPES.BLOCKQUOTE,
      source: [
        "Face Spoofing leads to thousands of Identity-related frauds every month, the consequence of which is losses of millions of dollars faced by enterprises all around the world.",
      ],
    },
    {
      type: BLOT_CONTENT_UTIL_TYPES.PARAGRAPH,
      source: [
        "Any process that uses Face recognition faces a high risk of getting compromised by Face Spoofing, putting the security of billions of people at risk. Nevertheless, Deep Learning has a remedy: Liveness Detection",
      ],
    },
    {
      type: BLOT_CONTENT_UTIL_TYPES.HEADING,
      source: ["What is Liveness Detection?"],
    },
    {
      type: BLOT_CONTENT_UTIL_TYPES.PARAGRAPH,
      source: [
        "Liveness Detection is a technique that detects Genuine Faces v/s Spoof Faces and prevents Face Spoofing in a Face Recognition system. The goal behind Liveness Detection is to tell whether the user “A” is actually present behind the camera and not a fraudster “F” pretending to be “A” using “A”’s photographs, face masks, and pictures displayed on a digital device, etc.",
        "There are two primary ways to check the user’s Liveness:",
      ],
    },
    {
      type: BLOT_CONTENT_UTIL_TYPES.HEADING,
      source: ["Active Liveness Detection"],
    },
    {
      type: BLOT_CONTENT_UTIL_TYPES.PARAGRAPH,
      source: [
        "Active Liveness Detection works with the Active involvement of the end-user. These systems are interactive and force users to perform Live actions like:",
      ],
    },
    {
      type: BLOT_CONTENT_UTIL_TYPES.LIST,
      source: [
        "Blinking, Smiling",
        "Turning their head left, right, up, down",
        "Performing gestures like striking a pose with fingers, or writing something on paper and displaying it.",
      ],
    },
    {
      type: BLOT_CONTENT_UTIL_TYPES.HEADING,
      source: ["Passive Liveness Detection"],
    },
    {
      type: BLOT_CONTENT_UTIL_TYPES.PARAGRAPH,
      source: [
        "Passive Liveness Detection systems work simply by asking users to click and submit a selfie/photograph.",
        "This technique provides safeguarding against face spoofing attacks without any end-user effort. It also has the lowest drop-off rates, adds no expectancy to the user base journey, and is particularly effective for slow networks.",
        "The execution of this method requires the least amount of developer involvement compared to the other options on this list.",
      ],
    },
    {
      type: BLOT_CONTENT_UTIL_TYPES.BLOCKQUOTE,
      source: ["Face Recognition + Liveness = Complete Authentication"],
    },
    {
      type: BLOT_CONTENT_UTIL_TYPES.HEADING,
      source: ["Combining Face Recognition with Passive Liveness Detection"],
    },
    {
      type: BLOT_CONTENT_UTIL_TYPES.PARAGRAPH,
      source: [
        "Due to its seamless user experience and faster turnaround time than active liveness systems, a passive liveness solution is ideal for securing any face-recognition-based task against spoofing attacks.",
        "Passive Liveness Detection systems require just the selfie of the end-user that is used to do a face match. The selfie is sent to a REST API endpoint where the Liveness Detection algorithms predict the “liveness” score of the face under consideration. If the predicted “score” falls below a set threshold, the face is said to be a spoofing attack and the response is returned.",
        "Passive Liveness detection systems have low dropoff rates and can be integrated with live CCTV feed, websites, mobile apps, etc using a few lines of code.",
      ],
    },
    {
      type: BLOT_CONTENT_UTIL_TYPES.HEADING,
      source: ["The Future of Face Recognition"],
    },
    {
      type: BLOT_CONTENT_UTIL_TYPES.PARAGRAPH,
      source: [
        "By 2025, 1.5 Billion people will be using their face biometrics to carry out online transactions, check-in physical places (airports, hotels, etc.), sign in to their favorite clothing brand’s online portal, etc. Almost any task involving personal identity is going to be affected by face recognition algorithms which require the underlying face-matching process to be completely fool-proof.",
      ],
    },
    {
      type: BLOT_CONTENT_UTIL_TYPES.IMAGE,
      source: [futureFaceImage],
    },
    {
      type: BLOT_CONTENT_UTIL_TYPES.PARAGRAPH,
      source: [
        "A strong Liveness Detection system is critical to ensure that safety is not compromised in the Face Matching process. Face Antispoofing is becoming a must-have in a system/product that uses face recognition.",
      ],
    },
    ...ABOUT_SPOOFSENSE,
  ],
};

export default FACE_LIVENESS_DETECTION;
