import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";

// Style
import "./App.css";
import { Helmet } from "react-helmet";

// Components
import SignIn from "./components/SignIn/SignIn";
import SignUp from "./components/SignUp/SignUp";
import Layout from "./containers/Layout/Layout";
import useDataLayer from "./helpers/StateProvider";
import Product from "./pages/Admin/Product/Product.js";
import User from "./pages/Admin/User/User.js";
import BlogPage from "./pages/Blog/BlogPage/BlogPage";
import BlogPost from "./pages/Blog/BlogPost/BlogPost";
import Demo from "./pages/Demo/Demo";
import Integration from "./pages/Integration/Integration";
import LandingPage from "./pages/LandingPage/LandingPage";
import Products from "./pages/Products/Products";
import ResetPassword from "./pages/ResetPassword/ResetPassword";
import Settings from "./pages/Settings/Settings";
import authService from "./services/auth-service";
import TopBanner from "./components/TopBanner/TopBanner.js";

const App = () => {
  const [{}, dispatch] = useDataLayer();

  useEffect(() => {
    const user = authService.getCurrentUser();
    user
      ? dispatch({ type: "SET_USER", user: user })
      : dispatch({ type: "SET_USER", user: null });
  }, []);

  return (
    <div className="app">
      <Helmet>
        <meta charSet="utf-8" />
        <title>SpoofSense - Face Liveness Detection APIs and SDKs</title>
        <description>
          SpoofSense offers the most accurate face liveness detection and fraud
          prevention solutions for all industries. Deliver security at scale
          using SpoofSense APIs and Mobile SDKs.
        </description>
      </Helmet>
      <Router>
        <Routes>
          <Route
            path="/"
            element={
              <>
                <TopBanner />
                <LandingPage />
              </>
            }
          />
          <Route
            path="/home"
            element={
              <>
                <TopBanner />
                <LandingPage />
              </>
            }
          />
          <Route path="/signin" element={<SignIn />} />
          <Route path="/signup" element={<SignUp />} />
          <Route
            path="/blogs"
            element={
              <>
                <TopBanner />
                <BlogPage />
              </>
            }
          />
          <Route
            path="/blogs/:blogLink"
            element={
              <>
                <TopBanner />
                <BlogPost />
              </>
            }
          />
          <Route
            path="/products/*"
            element={<Layout Page={Products} route="/products" />}
          />
          <Route
            path="/products/:productId/demo/*"
            element={<Layout Page={Demo} route="/products/:productId/demo/" />}
          />
          <Route
            path="/products/:productId/integration/*"
            element={<Layout Page={Integration} route="/integration" />}
          />
          <Route
            path="/settings/*"
            element={<Layout Page={Settings} route="/settings" />}
          />
          <Route
            path="/manageusers/*"
            element={<Layout Page={User} route="/manageusers" />}
          />
          <Route
            path="/manageproducts/*"
            element={<Layout Page={Product} route="/manageproducts" />}
          />
          <Route path="/reset-password" element={<ResetPassword />} />
        </Routes>
      </Router>
    </div>
  );
};

export default App;
