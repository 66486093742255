import { blogContent } from "./blogContent.js";

export function getBlogCard(link) {
  return blogContent.find((card) => card.link === link) || null;
}

export function capitalizeAndJoin(arr) {
  return arr?.map((str) => str.toUpperCase()).join(", ") || "";
}

export const BLOT_CONTENT_UTIL_TYPES = {
  HEADING: "heading",
  PARAGRAPH: "paragraph",
  IMAGE: "image",
  BLOCKQUOTE: "blockquote",
  LIST: "list",
};

export const ABOUT_SPOOFSENSE = [
  {
    type: BLOT_CONTENT_UTIL_TYPES.HEADING,
    source: ["About SpoofSense"],
  },
  {
    type: BLOT_CONTENT_UTIL_TYPES.PARAGRAPH,
    source: [
      "We at <a href='https://spoofsense.ai/' target='__blank'>Spoofsense</a> offer state of the art Passive Facial Liveness solutions. The accuracy and performance of the SpoofSense Passive Facial Liveness is certified by iBeta, making it one of the most accurate Liveness solutions worldwide.",
      "It is robust at tackling these forms of spoofing attacks and enables any face recognition system with anti spoofing capabilities.",
      "Looking for a Liveness Detection Solution for your use-case? Get a free demo <a href='https://cal.com/kartikeya-bhardwaj-spoofsense/30min' target='__blank'>here</a>.",
    ],
  },
];
