const nodejsNative = `var https = require('follow-redirects').https;
var fs = require('fs');

var options = {
'method': 'POST',
'hostname': 'rah9bywlua.execute-api.ap-south-1.amazonaws.com',
'path': '/prod/antispoofing',
'headers': {
 },
 'maxRedirects': 20
};
  
var req = https.request(options, function (res) {
  var chunks = [];
  
  res.on("data", function (chunk) {
    chunks.push(chunk);
  });
  
  res.on("end", function (chunk) {
     var body = Buffer.concat(chunks);
     console.log(body.toString());
  });
  
  res.on("error", function (error) {
     console.error(error);
    });
});

var postData =  "{\\"data\\":\\"BASE64_ENCODED_DATA"}
req.write(postData);

req.end();
`;

const pythonRequest = `import requests

url = "https://rah9bywlua.execute-api.ap-south-1.amazonaws.com/prod/antispoofing"

payload =  "{\\"data\\":\\"BASE64_ENCODED_DATA"}
headers {
  key: x-api-key
  value: Your_API_KEY
}

response = requests.request("POST", url, headers=headers, data=payload)

print(response.text)
`;

const phpHTTP = `<?php
require_once 'HTTP/Request2.php';
$request = new HTTP_Request2();
$request->setUrl('https://rah9bywlua.execute-api.ap-south-1.amazonaws.
com/prod/antispoofing');
$request->setMethod(HTTP_Request2::METHOD_POST);
$request->setConfig(array(
  'follow_redirects' => TRUE
));
$request->setHeader(array(
  
));
$request->setBody('{"data":"BASE64_ENCODED_DATA"}')
try {
  $response = $request->send();
   if ($response->getStatus() == 200) {
     echo $response->getBody();
  }
  else {
     echo 'Unexpected HTTP status: ' . $response->getStatus() . ' ' .
         $response->getReasonPhrase();
  }
}
 catch(HTTP_Request2_Exception $e) {
   echo 'Error: ' . $e->getMessage();
}
`;

const rubyHTTP = `require "uri"
require "net/http"

url = URI("https://rah9bywlua.execute-api.ap-south-1.amazonaws.com
/prod/antispoofing")

https = Net::HTTP.new(url.host, url.port)
https.use_ssl = true

request = Net::HTTP::Post.new(url)
request.body = "{\\"data\\":\\"BASE64_ENCODED_DATA"}

response = https.request(request)
puts response.read_body
`;

const javaHTTP = `OkHttpClient client = new OkHttpClient().newBuilder().build();
MediaType mediaType = MediaType.parse("text/plain");

RequestBody body = RequestBody.create(mediaType, "{"data":
"BASE64_ENCODED_DATA"}");

Request request = new Request.Builder()
  .url("https://rah9bywlua.execute-api.ap-south-1.amazonaws.com/prod/antispoofing")
  .method("POST", body)
  .build(); 

Response response = client.newCall(request).execute();
`;

const codeSnippets = [
  {
    title: "Nodejs",
    language: "javascript",
    code: nodejsNative,
  },
  {
    title: "Python",
    language: "python",
    code: pythonRequest,
  },
  {
    title: "Php",
    language: "php",
    code: phpHTTP,
  },
  {
    title: "Ruby",
    language: "ruby",
    code: rubyHTTP,
  },
  {
    title: "Java",
    language: "java",
    code: javaHTTP,
  },
];

export default codeSnippets;
