import React, { useState } from "react";
import copy from "copy-to-clipboard";

import "./SandboxKeyCard.css";
import useDataLayer from "../../helpers/StateProvider";
import { dashboardContent } from "../../helpers/dashboardContent";

// let structureAPIKey = (api) => {
//   let structuredApi = "";
//   for (let i = 0; i < api.length; i++) {
//     if (i !== 0 && i % 4 === 0) {
//       structuredApi += "-";
//     }
//     structuredApi += api[i];
//   }
//   return structuredApi;
// };

export const timer = (delay) => new Promise((res) => setTimeout(res, delay));

const SandboxKeyCard = () => {
  const [showCopied, setShowCopied] = useState(false);
  const [{ user }, dispatch] = useDataLayer();

  // let api = structureAPIKey(API_KEY);
  let api = "";
  if (user) api = user?.user?.apiKey;
  if (!api.length) api = "NO-API-KEY";

  let pathName = window.location.pathname;
  pathName = pathName.substring(1);
  const paths = pathName.split("/");

  const copyApiKey = async () => {
    setShowCopied(true);
    copy(api);
    await timer(1500);
    setShowCopied(false);
  };

  return (
    <div className="sandboxkeycard">
      <div className="sandboxkeycard__header">
        <h2>Your Sandbox Keys</h2>
        <a
          target="_blank"
          rel="noreferrer"
          href={dashboardContent[paths[1]].documentationLink}
        >
          See Documentation
        </a>
      </div>
      <div className="sandboxkeycard__body">
        <h3>Publishable API Key</h3>
        <div className="sanboxkeycard__body-below">
          <h3>{api}</h3>
          <button
            onClick={copyApiKey}
            className="sandboxkeycard__body-below__copy"
          >
            {showCopied ? "Copied" : "Copy"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default SandboxKeyCard;
