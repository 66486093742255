import React from "react";
import UsersComponent from "../../../components/UsersComponent/UsersComponent";

import "./User.css";

const User = () => {
  return (
    <div className="user">
      <UsersComponent />
    </div>
  );
};

export default User;
