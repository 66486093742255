import React, { useState } from "react";

import "./ProductCard.css";

import { HiDocumentText } from "react-icons/hi";
import { useNavigate } from "react-router-dom";
import useDataLayer from "../../helpers/StateProvider";
import { activateProductTrial } from "../../helpers/helpers";
import { dashboardContent } from "../../helpers/dashboardContent";

import { BeatLoader } from "react-spinners";
import { useEffect } from "react";

const ProductCard = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [productInProgress, setProductInProgress] = useState(false);
  const navigate = useNavigate();

  const [{ user }, dispatch] = useDataLayer();

  const productName = props.productName.toLowerCase().replace(/ /g, "");

  const navigateHandler = () => {
    props.productName && navigate(`/products/${productName}/demo`);
  };

  useEffect(() => {
    if (!dashboardContent[productName]) setProductInProgress(true);
  }, []);

  const getTrial = async () => {
    if (user && user.tokens) {
      const accessToken = user?.tokens?.access?.token;
      setIsLoading(true);
      const success = await activateProductTrial(props.id, accessToken);
      success && window.location.reload();
      setIsLoading(false);
    }
  };

  return (
    <div className="productcard">
      <HiDocumentText
        style={{ fontSize: "4rem", color: "var(--primary-color-dark)" }}
      />
      <h1>{props.productName}</h1>
      <p>{props.description}</p>
      <div
        className="productcard__bottom"
        style={{
          justifyContent: props.allProducts ? "center" : "space-between",
        }}
      >
        {!props.allProducts && (
          <div className="productcard__bottom-apicall">
            <span>API call left</span>
            <h1 style={{ marginTop: "-0.4rem" }}>{props.apiLimit}</h1>
          </div>
        )}

        <button
          disabled={productInProgress || props.apiLimit <= 0}
          style={{
            backgroundColor:
              productInProgress || props.apiLimit <= 0 ? "grey" : "",
            cursor: !productInProgress
              ? props.apiLimit > 0 || props.allProducts
                ? "pointer"
                : "not-allowed"
              : "not-allowed",
          }}
          onClick={props.allProducts ? getTrial : navigateHandler}
        >
          {" "}
          {props.allProducts ? (
            isLoading ? (
              <BeatLoader size={6} color="white" />
            ) : productInProgress ? (
              "Product Under Development"
            ) : (
              "Get Trial"
            )
          ) : props.apiLimit > 0 ? (
            "Go To Dashboard"
          ) : (
            "API Calls Exhausted"
          )}
        </button>
      </div>
    </div>
  );
};

export default ProductCard;
