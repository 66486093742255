import React from "react";
import "./ProductInfo.css";
import iBetaCertificate from "../../../assets/images/ibeta-certificate-white-border.png";
import spoofGirl from "../../../assets/images/soofgirl.png";

const ProductInfo = () => {
  return (
    <div className="landingpage__product-info-wrapper">
      <section className="landingpage__product-info">
        <img src={iBetaCertificate} alt="ibeta-ceritification" />
        <div className="landingpage__product-info-blog">
          <div className="landingpage__product-info-blog__thumbnail">
            <img
              src={spoofGirl}
              alt="Deepfakes Injections: A Threat to Biometric Security"
            />
          </div>
          <div className="landingpage__product-info-blog__para">
            <h1>Deepfakes Injections: A Threat to Biometric Security</h1>
            <p>
              In the realm of biometric security, the emergence of deepfakes
              presents a complex and evolving challenge. Traditional biometric
              systems, particularly those relying on facial recognition, depend
              heavily on Presentation Attack Detection (PAD) to verify the
              liveness of a biometric input. PAD is designed to differentiate
              between actual human presence and non-live representations such as
              photographs, video replays, or sophisticated 3D masks.
            </p>
            <p>
              However, deepfake technology introduces a more insidious form of
              threat known as injection attacks. These attacks exploit
              vulnerabilities in hardware and software to circumvent the
              standard capture process. By emulating a legitimate camera feed,
              fraudsters can present digitally altered or entirely synthetic
              facial imagery, potentially deceiving even advanced liveness
              detection systems.
            </p>
            <p>
              The danger extends beyond mere identity theft. Deepfake injections
              can be leveraged to create entirely new, synthetic identities.
              These fabricated personas can be used for a range of criminal
              activities, from opening fraudulent accounts to gaining
              unauthorized access to existing accounts of unsuspecting victims.
              The sophistication of these attacks means that even systems
              equipped with traditional PAD may fall short.
            </p>
            <p>
              Recognizing this evolving threat landscape, SpoofSense's advanced
              AI-driven solutions are specifically engineered to counteract
              deepfake injections. By employing deep-learning based computer
              vision algorithms, SpoofSense not only identifies typical
              presentation attacks but also effectively detects injection
              attacks, ensuring a robust defense against the ever-advancing tide
              of digital fraud.
            </p>
          </div>
        </div>
      </section>
    </div>
  );
};

export default ProductInfo;
