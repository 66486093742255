import React, { useEffect, useState } from "react";
import Header from "../../components/Header/Header";

import Aos from "aos";
import "aos/dist/aos.css";

import "./LandingPage.css";

import forbes from "../../assets/svg/forbes.svg";
import yourStory from "../../assets/svg/your-story.svg";
import indiaToday from "../../assets/svg/india-today.svg";
import inc42 from "../../assets/svg/inc42.svg";
import ongrid from "../../assets/svg/ongrid.svg";
import mymobiforce from "../../assets/svg/mymobiforce.svg";

import spoofsenseLogo from "../../assets/svg/SpoofSense.svg";
import BookDemo from "../../containers/BookDemo/BookDemo";

import { useNavigate } from "react-router-dom";
import HeroVideo from "../../components/LandingPage/HeroVideo/HeroVideo";
import Wrapper from "../../components/LandingPage/Wrapper/Wrapper";

const LandingPage = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [showBookDemo, setShowBookDemo] = useState(false);

  let navigate = useNavigate();

  useEffect(() => {
    Aos.init({
      offset: 300,
      duration: 800,
      easing: "ease-in-sine",
      delay: 50,
    });
  }, []);

  return (
    <>
      <div className="landingpage__wrapper">
        <div className="landingpage">
          <Header
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            setShowBookDemo={setShowBookDemo}
          />
          <div className={`containerOver ${isOpen && "open"}`}>
            <ul className="containerOver-list">
              <a
                href="https://spoofsense.gitbook.io/spoofsense-developer-documentation/"
                target="__blank"
              >
                <li>Documentation</li>
              </a>
              <li onClick={() => navigate("/blogs")}>Blog</li>
              <a
                href="https://cal.com/kartikeya-bhardwaj-spoofsense/30min"
                target="__blank"
              >
                <li>Book Demo</li>
              </a>
            </ul>
          </div>
          <HeroVideo />
          {/* <section data-aos="fade-up" className="landingpage__hero">
            <div className="landingpage__hero-left">
              <h3>Advanced</h3>
              <h1>
                Passive Facial <br /> Liveness Detection
              </h1>
              <p>
                Deliver Security at scale with our frictionless biometrics.
                Explore our Face Liveness Detection APIs, SDKs and more.
              </p>
              <img
                src={ibetaCertificationLogo}
                alt="ibeta-certification-logo"
              />
              <div className="landingpage__hero-buttons">
                <a
                  href="https://cal.com/kartikeya-bhardwaj-spoofsense/30min"
                  target="__blank"
                >
                  <button
                    style={{
                      backgroundColor: "var(--dark-blue)",
                      color: "white",
                    }}
                  >
                    Book Demo
                  </button>
                </a>
              </div>
            </div>
            <div className="landingpage__hero-right">
              {!window.navigator.platform.match(/^iPhone/) &&
              !window.navigator.platform.match(/^Mac/) ? (
                <LandingPageVideo video={vid1} poster={img1} />
              ) : (
                <img src={img1} alt="spoofsense" />
              )}
            </div>
          </section> */}

          <section className="landingpage__customers">
            <h2>Our Customers</h2>
          </section>

          <section className="landingpage__brands">
            <a href="https://ongrid.in/" target="__blank">
              <img src={ongrid} alt="ongrid" />
            </a>

            <a href="https://www.bureau.id/" target="__blank">
              <img src={forbes} alt="forbes" />
            </a>

            <a href="https://dojah.io/" target="__blank">
              <img src={yourStory} alt="your-story" />
            </a>

            <a href="https://www.signzy.com/" target="__blank">
              <img src={indiaToday} alt="india-today" />
            </a>

            <a href="https://www.idcentral.io/" target="__blank">
              <img src={inc42} alt="inc-42" />
            </a>

            <a
              href="https://www.mymobiforce.com/"
              target="__blank"
              className="mobiforce-logo"
            >
              <img src={mymobiforce} alt="mymobiforce" />
            </a>
          </section>

          <Wrapper />

          {/* <section data-aos="fade-up" className="landingpage__antilayer">
            <div className="landingpage__antilayer-left">
              {!window.navigator.platform.match(/^iPhone/) &&
              !window.navigator.platform.match(/^Mac/) ? (
                <LandingPageVideo video={vid2} poster={img2} />
              ) : (
                <img src={img2} alt="spoofsense" />
              )}
            </div>
            <div className="landingpage__antilayer-right">
              <h3>Secure</h3>
              <h1>
                Your Face <br /> Recognition Workflow
              </h1>
              <p>
                Integrate SpoofSense Passive Face Liveness with your Face
                recognition tasks and Prevent Identity Fraud. <br /> <br />{" "}
                SpoofSense requires just a single selfie to detect the face
                liveness, with less than 2 seconds of turn around time,
                providing a frictionless user-experience.
              </p>
            </div>
          </section> */}

          {/* <div className="landingpage__usersafe-wrapper">
            <section data-aos="fade-up" className="landingpage__usersafe">
              <div className="landingpage__usersafe-left">
                <h3>Strengthen</h3>
                <h1>
                  Your KYC and <br /> Onboarding Process
                </h1>
                <p>
                  Make KYC/Customer onboarding spoof-proof with SpoofSense’s
                  Face Liveness Engine that provides state-of-the-art
                  performance. Explore the API docs and integrate our solutions
                  in your application using just a few lines of code.
                  <br /> <br />{" "}
                  <a
                    href="https://spoofsense.gitbook.io/spoofsense-developer-documentation/"
                    target="__blank"
                    style={{
                      color: "white",
                      position: "relative",
                      top: "-0.5rem",
                    }}
                  >
                    Explore docs
                  </a>
                </p>
              </div>
              <div className="landingpage__usersafe-right">
                {!window.navigator.platform.match(/^iPhone/) &&
                !window.navigator.platform.match(/^Mac/) ? (
                  <LandingPageVideo video={vid3} poster={img3} />
                ) : (
                  <img src={img3} alt="spoofsense" />
                )}
              </div>
            </section>
          </div> */}

          {/* <div className="landingpage__features-wrapper">
            <h1>Use Cases</h1>
            <section data-aos="fade-up" className="landingpage__features">
              <div>
                <img src={icon3} alt="" />
                <h3>Customer Onboarding</h3>
                <p>
                  Identity fraud in Customer onboarding is a rising problem. Use
                  SpoofSense’s world class Face Liveness technology to prevent
                  identity theft at scale.
                </p>
              </div>
              <div>
                <img src={icon1} alt="" />
                <h3>Video KYC</h3>
                <p>
                  VideoKYCs are prone to Face recognition frauds that are easily
                  produced by fraudsters. Explore SpoofSense’s APIs and SDKs to
                  protect your VideoKYC stack from Spoofing.
                </p>
              </div>
              <div>
                <img src={icon4} alt="" />
                <h3>Face Recognition</h3>
                <p>
                  Face recognition algortihms are prone to spoofing and are
                  risky to deploy in production. An Antispoofing integration
                  like SpoofSense makes it easy and safe to deploy and scale
                  Face Recognition based tasks
                </p>
              </div>
              <div>
                <img src={icon2} alt="" />
                <h3>Profile Verification</h3>
                <p>
                  Face spoofing attacks are easy to produce and can fool face
                  recognition/verification systems with just a picture of the
                  face displayed digitally or in printed form
                </p>
              </div>
            </section>
          </div> */}

          <div className="landingpage__fightfraud-wapper">
            <section className="landingpage__fightfraud">
              <h1>Ready to fight fraud?</h1>
              <h1>Get Started</h1>
              <div>
                <a
                  href="https://cal.com/kartikeya-bhardwaj-spoofsense/30min"
                  target="__blank"
                >
                  <button style={{ backgroundColor: "white" }}>
                    Book Demo
                  </button>
                </a>
              </div>
              {/* <div style={{ marginBottom: "0rem" }}>
                <div>
                  <a
                    href="https://www.forbesindia.com/audio/forbes-india-daily-tech-brief-podcast/doordash-buys-wolt-for-8-bln-facebook-releases-data-on-bullying-and-harassment-and-a-chat-with-kartikeya-bhardwaj-of-spoofsense/71455"
                    target="__blank"
                  >
                    <img src={forbes} alt="forbes" />
                  </a>
                  <img src={yourStory} alt="your-story" />
                </div>
                <div>
                  <img src={indiaToday} alt="india-today" />
                  <a
                    href="https://inc42.com/features/30-startups-to-watch-the-startups-that-caught-our-eye-in-january-2022/"
                    target="__blank"
                  >
                    <img src={inc42} alt="inc-42" />
                  </a>
                </div>
              </div> */}
            </section>
          </div>

          <footer className="landingpage__footer">
            <div className="landingpage__footer-top">
              <div>
                <div>
                  <img src={spoofsenseLogo} alt="spoofsense_logo" />
                </div>
                <div className="landingpage__footer-connect">
                  <h4>Connect</h4>
                  <ul>
                    <a
                      href="https://mail.google.com/mail/?view=cm&fs=1&tf=1&to=kartikeya@spoofsense.com"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <li>Email us</li>
                    </a>
                    <a href="https://twitter.com/spoofsense">
                      <li>Twitter</li>
                    </a>
                    <a href="https://www.linkedin.com/company/spoofsense">
                      <li>Linkedin</li>
                    </a>
                  </ul>
                </div>
              </div>

              <div>
                <div>
                  <h4>Support</h4>
                  <ul>
                    <a
                      target="__blank"
                      href="https://spoofsense.gitbook.io/spoofsense-developer-documentation/"
                    >
                      <li>Documentation</li>
                    </a>
                    <a
                      href="https://cal.com/kartikeya-bhardwaj-spoofsense/30min"
                      target="__blank"
                    >
                      <li>Book a Demo</li>
                    </a>
                  </ul>
                </div>
                <div>
                  <h4>Company</h4>
                  <ul>
                    <li>Privacy</li>
                    <li>Terms & Conditions</li>
                  </ul>
                </div>
              </div>
            </div>
          </footer>
        </div>
      </div>
      {showBookDemo && <BookDemo close={() => setShowBookDemo(false)} />}
    </>
  );
};

export default LandingPage;
