import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";

import "./AddNewUserModal.css";
import { MdOutlineCancel } from "react-icons/md";
import { signupFormvalidate } from "../../helpers/validation";
import { BeatLoader } from "react-spinners";
import useDataLayer from "../../helpers/StateProvider";
import { addNewUser } from "../../helpers/helpers";

import { BiShow, BiHide } from "react-icons/bi";

const AddNewUserModal = (props) => {
  const [addNewUserFormValues, setAddNewUserFormValues] = useState({
    firstName: "",
    lastName: "",
    email: "",
    company: "",
    role: "",
    password: "",
  });
  const [addNewUserFormErrors, setAddNewUserFormErrors] = useState({});
  const [addNewUserIsSubmit, setAddNewUserIsSubmit] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const addNewUserInputHandler = (event) => {
    const { name, value } = event.target;
    setAddNewUserFormValues({ ...addNewUserFormValues, [name]: value });
  };

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const signupSubmitHandler = (event) => {
    event.preventDefault();
    setAddNewUserFormErrors(signupFormvalidate(addNewUserFormValues));
    setAddNewUserIsSubmit(true);
  };

  const [{ user }, dispatch] = useDataLayer();

  useEffect(() => {
    const setUser = async () => {
      if (!Object.keys(addNewUserFormErrors).length && addNewUserIsSubmit) {
        if (user) {
          const accessToken = user?.tokens?.access?.token;
          setIsLoading(true);
          const success = await addNewUser(addNewUserFormValues, accessToken);
          success && window.location.reload();
          setIsLoading(false);
        }
      }
    };
    setUser();
  }, [addNewUserFormErrors, addNewUserIsSubmit]);

  return ReactDOM.createPortal(
    <>
      <div className="addnewusermodal__overlay"></div>
      <div className="addnewusermodal">
        <MdOutlineCancel
          onClick={props?.close}
          className="addnewusermodal__close"
        />
        <h1 className="addnewusermodal__title">Add New User</h1>
        <form
          onSubmit={signupSubmitHandler}
          className="addnewusermodal__form"
          autoComplete="off"
        >
          <div className="addnewusermodal__form-top">
            <div className="addnewusermodal__form-container">
              <label htmlFor="firstName">First Name</label>
              <input
                value={addNewUserFormValues.firstName}
                name="firstName"
                type="text"
                onChange={addNewUserInputHandler}
              />
              <p className="addnewusermodal__form-error">
                {addNewUserFormErrors?.firstName}
              </p>
            </div>
            <div className="addnewusermodal__form-container">
              <label htmlFor="lastName">Last Name</label>
              <input
                value={addNewUserFormValues.lastName}
                name="lastName"
                type="text"
                onChange={addNewUserInputHandler}
              />
              <p className="addnewusermodal__form-error">
                {addNewUserFormErrors?.lastName}
              </p>
            </div>
          </div>
          <div className="addnewusermodal__form-center">
            <div className="addnewusermodal__form-container">
              <label htmlFor="email">EMail</label>
              <input
                value={addNewUserFormValues.email}
                name="email"
                type="email"
                onChange={addNewUserInputHandler}
              />
              <p className="addnewusermodal__form-error">
                {addNewUserFormErrors?.email}
              </p>
            </div>
            <div className="addnewusermodal__form-container">
              <label htmlFor="role">Role</label>
              <select
                className="addnewusermodal__select"
                name="role"
                defaultValue={"DEFAULT"}
                onChange={addNewUserInputHandler}
              >
                <option value="DEFAULT" disabled hidden>
                  Select a role
                </option>
                <option value="developer">Developer</option>
                <option value="enterpriseAdmin">Enterprise</option>
                <option value="admin">Admin</option>
              </select>
              <p className="addnewusermodal__form-error">
                {addNewUserFormErrors?.role}
              </p>
            </div>
          </div>
          <div className="addnewusermodal__form-bottom">
            <div className="addnewusermodal__form-container">
              <label htmlFor="company">Company</label>
              <input
                value={addNewUserFormValues.company}
                name="company"
                type="text"
                onChange={addNewUserInputHandler}
              />
              <p className="addnewusermodal__form-error">
                {addNewUserFormErrors?.company}
              </p>
            </div>
            <div className="addnewusermodal__form-container addnewusermodal__pwd-input">
              <label htmlFor="password">Password</label>
              <input
                name="password"
                type={showPassword ? "text" : "password"}
                placeholder="atleast 8 characters"
                value={addNewUserFormValues.password}
                onChange={addNewUserInputHandler}
              />
              <p className="addnewusermodal__form-error">
                {addNewUserFormErrors?.password}
              </p>
              {showPassword ? (
                <BiShow
                  onClick={toggleShowPassword}
                  className="addnewusermodal__pwd-eye"
                />
              ) : (
                <BiHide
                  onClick={toggleShowPassword}
                  className="addnewusermodal__pwd-eye"
                />
              )}
            </div>
          </div>
          <button
            disabled={isLoading}
            type="submit"
            className="addnewusermodal__adduser-btn"
          >
            {isLoading ? <BeatLoader size={6} color="white" /> : "Add User"}
          </button>
        </form>
      </div>
    </>,
    document.getElementById("portal")
  );
};

export default AddNewUserModal;
