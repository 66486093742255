import React from "react";

import "./DemoResultCard.css";

import { AiFillCheckCircle } from "react-icons/ai";
import { MdDisabledByDefault } from "react-icons/md";
import useDataLayer from "../../helpers/StateProvider";

import PuffLoader from "react-spinners/PuffLoader";

const DemoResultCard = () => {
  const [{ demoImage, demoLoading }, dispatch] = useDataLayer();

  if (demoLoading) {
    return (
      <div className="demoresultcard">
        <PuffLoader color="var(--primary-color)" />
      </div>
    );
  }

  if (!demoImage) return null;

  const classRes = demoImage?.model_output?.pred_idx;
  const confidenceRes = demoImage?.model_output?.pred_score;
  const success = demoImage?.success;

  return (
    <div className="demoresultcard">
      {success ? (
        <>
          <h2>
            The face is{" "}
            {classRes === "real" ? (
              <span className="demoresultcard__real-img">
                <p>{"\u00A0"}Real</p>
                {"\u00A0"}
                <AiFillCheckCircle />
              </span>
            ) : (
              <span className="demoresultcard__spoof-img">
                <p>{"\u00A0"}Spoof</p>
                {"\u00A0"}
                <MdDisabledByDefault />
              </span>
            )}
          </h2>
          <h2>Model Confidence is {confidenceRes}</h2>
        </>
      ) : (
        <p style={{ textAlign: "center" }}>
          Oops..something went wrong. Please share the image with our support
          team <strong>support@spoofsense.com</strong> and we will resolve the
          issue :)
        </p>
      )}
    </div>
  );
};

export default DemoResultCard;
