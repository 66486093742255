import React from "react";

import "./Integration.css";

import SandboxKeyCard from "../../components/SandboxKeyCard/SandboxKeyCard";
import CodeSwitcher from "../../containers/CodeSwitcher/CodeSwitcher";
import PageDescription from "../../components/PageDescription/PageDescription";
import { dashboardContent } from "../../helpers/dashboardContent";

const Integration = () => {
  let pathName = window.location.pathname;
  pathName = pathName.substring(1);
  const paths = pathName.split("/");
  return (
    <div className="integration">
      <PageDescription
        title={dashboardContent[paths[1]].integrationTitle}
        subTitle={dashboardContent[paths[1]].integrationSubtitle}
      />
      <SandboxKeyCard />
      <CodeSwitcher />
    </div>
  );
};

export default Integration;
