import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";

import "./EditUserModal.css";

import { MdOutlineCancel } from "react-icons/md";
import { FaUserCircle } from "react-icons/fa";
import { editUserFormvalidate } from "../../helpers/validation";
import useDataLayer from "../../helpers/StateProvider";
import { editUser } from "../../helpers/helpers";
import { BeatLoader } from "react-spinners";

const EditUserModal = (props) => {
  const userId = props?.userDetails[0]?.value;
  const firstName = props?.userDetails[1]?.value;
  const lastName = props?.userDetails[2]?.value;
  const email = props?.userDetails[3]?.value;
  const company = props?.userDetails[4]?.value;
  const role = props?.userDetails[5]?.value;
  const apiKey = props?.userDetails[6]?.value;

  const [editUserFormValues, setEditUserFormValues] = useState({
    firstName: firstName,
    lastName: lastName,
    email: email,
    company: company,
    role: role,
    apiKey: apiKey,
  });
  const [editUserFormErrors, setEditUserFormErrors] = useState({});
  const [editUserIsSubmit, setEditUserIsSubmit] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const editUserInputHandler = (event) => {
    const { name, value } = event.target;
    setEditUserFormValues({ ...editUserFormValues, [name]: value });
  };

  const signupSubmitHandler = (event) => {
    event.preventDefault();
    setEditUserFormErrors(editUserFormvalidate(editUserFormValues));
    setEditUserIsSubmit(true);
  };

  const [{ user }, dispatch] = useDataLayer();

  useEffect(() => {
    const setUser = async () => {
      if (!Object.keys(editUserFormErrors).length && editUserIsSubmit) {
        if (user) {
          const accessToken = user?.tokens?.access?.token;
          setIsLoading(true);
          const success = await editUser(
            accessToken,
            userId,
            editUserFormValues
          );
          success && window.location.reload();
          setIsLoading(false);
        }
      }
    };
    setUser();
  }, [editUserFormErrors, editUserIsSubmit]);

  return ReactDOM.createPortal(
    <>
      <div className="editusermodal__overlay"></div>
      <div className="editusermodal">
        <MdOutlineCancel
          onClick={props?.close}
          className="editusermodal__close"
        />
        <h1 className="editusermodal__title">Edit User</h1>
        <div className="editusermodal__profile">
          <FaUserCircle style={{ fontSize: "3.8rem" }} />
          <div className="editusermodal__profile-right">
            <h2>{`${firstName} ${lastName}`}</h2>
            <span>{role}</span>
          </div>
        </div>
        <form onSubmit={signupSubmitHandler} className="editusermodal__form">
          <div className="editusermodal__form-top">
            <div className="editusermodal__form-container">
              <label htmlFor="firstName">First Name</label>
              <input
                value={editUserFormValues.firstName}
                name="firstName"
                type="text"
                onChange={editUserInputHandler}
              />
              <p className="edituser__form-error">
                {editUserFormErrors?.firstName}
              </p>
            </div>
            <div className="editusermodal__form-container">
              <label htmlFor="lastName">Last Name</label>
              <input
                value={editUserFormValues.lastName}
                name="lastName"
                type="text"
                onChange={editUserInputHandler}
              />
              <p className="edituser__form-error">
                {editUserFormErrors?.lastName}
              </p>
            </div>
          </div>
          <div className="editusermodal__form-center">
            <div className="editusermodal__form-container">
              <label htmlFor="email">EMail</label>
              <input
                value={editUserFormValues.email}
                name="email"
                type="email"
                onChange={editUserInputHandler}
              />
              <p className="edituser__form-error">
                {editUserFormErrors?.email}
              </p>
            </div>
            <div className="editusermodal__form-container">
              <label htmlFor="role">Role</label>
              <select
                className="editusermodal__select"
                name="role"
                defaultValue={editUserFormValues.role}
                onChange={editUserInputHandler}
              >
                <option value="developer">Developer</option>
                <option value="enterpriseAdmin">Enterprise</option>
                <option value="admin">Admin</option>
              </select>
              <p className="edituser__form-error">{editUserFormErrors?.role}</p>
            </div>
          </div>
          <div className="editusermodal__form-bottom">
            <div className="editusermodal__form-container">
              <label htmlFor="company">Company</label>
              <input
                value={editUserFormValues.company}
                name="company"
                type="text"
                onChange={editUserInputHandler}
              />
              <p className="edituser__form-error">
                {editUserFormErrors?.company}
              </p>
            </div>
            <div className="editusermodal__form-container">
              <label htmlFor="apikey">API Key</label>
              <input
                name="apiKey"
                type="text"
                value={editUserFormValues.apiKey}
                onChange={editUserInputHandler}
              />
              <p className="edituser__form-error">
                {editUserFormErrors?.apiKey}
              </p>
            </div>
          </div>
          <button
            disabled={isLoading}
            type="submit"
            className="editusermodal__save-btn"
          >
            {isLoading ? <BeatLoader size={6} color="white" /> : "Save"}
          </button>
        </form>
      </div>
    </>,
    document.getElementById("portal")
  );
};

export default EditUserModal;
