import React from "react";
import "./ProductFeat.css";
import highlyAccurate from "../../../assets/images/accurate.png";
import frictionLess from "../../../assets/images/frictionless.png";
import simpleIntegration from "../../../assets/images/integration.png";

import iBetaCertification from "../../../assets/images/ibeta-certification-no-border.png";
import googleForStartup from "../../../assets/images/google-for-startup.png";

const FEATURES_DATA = [
  {
    thumbnail: highlyAccurate,
    title: "Highly Accurate",
    description:
      "SpoofSense Face is highly accurate in detecting presentation + deepfake attacks with lowest false-positive and lowest false negative rates in the industry.",
  },
  {
    thumbnail: frictionLess,
    title: "Frictionless",
    description:
      "Uses a single image to detect the liveness of a genuine user. Takes < 2 seconds to complete the liveness check.",
  },
  {
    thumbnail: simpleIntegration,
    title: "Simple Integration",
    description:
      "SpoofSense Face can be integrated using a simple cloud API, with On-Prem deployment available for data protection.",
  },
];

const ProductFeat = () => {
  return (
    <div className="landingpage__product-feat__wrapper">
      <section className="landingpage__product-feat">
        <div className="landingpage__product-feat_info">
          <h1>
            SpoofSense Face : Cutting-Edge Facial Liveness & Deepfake Fraud
            Detection
          </h1>
          <div>
            {FEATURES_DATA?.map((feat, index) => (
              <div key={index} className="landingpage__product-feat-card">
                <div>
                  <img
                    src={feat.thumbnail}
                    alt={feat.title}
                    style={
                      feat.thumbnail === highlyAccurate
                        ? {
                            position: "relative",
                            top: "-1.5rem",
                            left: "-1.5rem",
                          }
                        : {}
                    }
                  />
                </div>
                <div>
                  <h3>{feat.title}</h3>
                  <p>{feat.description}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="landingpage__product-feat_foot">
          <img src={iBetaCertification} alt="ibeta-certification" />
          <img src={googleForStartup} alt="google-for-startup" />
        </div>
      </section>
    </div>
  );
};

export default ProductFeat;
