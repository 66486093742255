import React, { useState } from "react";
import Header from "../../../components/Header/Header";
import "./BlogPage.css";
import BlogCard from "../../../components/BlogCard/BlogCard";
import { useNavigate } from "react-router-dom";
import { blogContent } from "../../../BlogContent/blogContent.js";

const BlogPage = () => {
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();

  return (
    <div className="blogpage">
      <Header isOpen={isOpen} setIsOpen={setIsOpen} OnBlogList />

      <div className="blogpage__content">
        <h2>Our Blog</h2>
        <h1>
          Insight and Advise <br /> from our Expert Team
        </h1>

        <div className="blogpage__list">
          {blogContent.map((blog, index) => (
            <BlogCard
              key={index}
              publishedDate={blog.publishedDate}
              title={blog.title}
              description={blog.description}
              thumbnail={blog.thumbnail}
              link={blog.link}
              minuteRead={blog.minuteRead}
              author={blog.author}
            />
          ))}
        </div>
      </div>
      <div class={`containerOver ${isOpen && "open"}`}>
        <ul className="containerOver-list">
          <li onClick={() => navigate("/")}>Home</li>
        </ul>
      </div>
    </div>
  );
};

export default BlogPage;
