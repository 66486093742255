import React from "react";
import LearnMoreForm from "../LearnMoreForm/LearnMoreForm";
import "./Wrapper.css";
import InfoCards from "../InfoCards/InfoCards";
import ProductInfo from "../ProductInfo/ProductInfo";
import ProductFeat from "../ProductFeat/ProductFeat";

const Wrapper = () => {
  return (
    <>
      <div className="landingpageinside__wrapper">
        <LearnMoreForm />
      </div>
      <InfoCards />
      <ProductInfo />
      <ProductFeat />
    </>
  );
};

export default Wrapper;
