import axios from "axios";

const BASE_URL = "https://dashboard-backend-nine.vercel.app/v1";

export const AUTH_API_URL = `${BASE_URL}/auth`;

// Response for spoof image
export const identifySpoof = (apiLink, base64) => {
  return axios
    .post(apiLink, {
      data: base64,
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      alert(error);
    });
};

export const changePasswordResponse = (email) => {
  return axios
    .post(`${BASE_URL}/auth/forgot-password`, {
      email: email,
    })
    .then((response) => {
      return true;
    })
    .catch((error) => {
      alert(error);
      return false;
    });
};

export const resetPasswordResponse = (urlParams, password) => {
  return axios
    .post(`${BASE_URL}/auth/reset-password?token=${urlParams?.token}`, {
      password: password,
    })
    .then((response) => {
      return true;
    })
    .catch((error) => {
      alert(error);
      return false;
    });
};

export const isAccessExpires = (accessExpireTime) => {
  const currTime = Date.now();
  const expTimeFormat = new Date(accessExpireTime);
  const expTime = expTimeFormat.getTime();
  if (currTime > expTime) return true;
  return false;
};

export const getNewTokens = (refreshToken) => {
  return axios
    .post(`${BASE_URL}/auth/refresh-tokens`, {
      refreshToken: refreshToken,
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      // alert(error);
      return false;
    });
};

export const getUserList = (accessToken) => {
  return axios
    .get(`${BASE_URL}/users`, {
      headers: { Authorization: `Bearer ${accessToken}` },
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return false;
    });
};

export const getProductsList = (accessToken) => {
  return axios
    .get(`${BASE_URL}/products`, {
      headers: { Authorization: `Bearer ${accessToken}` },
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return false;
    });
};

export const getOwnedProductsList = (accessToken) => {
  return axios
    .get(`${BASE_URL}/enterprise/myProducts`, {
      headers: { Authorization: `Bearer ${accessToken}` },
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return false;
    });
};

export const editUser = (accessToken, userId, editFormValues) => {
  return axios
    .patch(`${BASE_URL}/users/${userId}`, editFormValues, {
      headers: { Authorization: `Bearer ${accessToken}` },
    })
    .then((response) => {
      return true;
    })
    .catch((error) => {
      return false;
    });
};

export const editProduct = (accessToken, productId, editProductFormValues) => {
  return axios
    .patch(`${BASE_URL}/products/${productId}`, editProductFormValues, {
      headers: { Authorization: `Bearer ${accessToken}` },
    })
    .then((response) => {
      return true;
    })
    .catch((error) => {
      return false;
    });
};

export const addNewUser = (addUserFormValues, accessToken) => {
  return axios
    .post(`${BASE_URL}/users`, addUserFormValues, {
      headers: { Authorization: `Bearer ${accessToken}` },
    })
    .then((response) => {
      return true;
    })
    .catch((error) => {
      // alert(error);
      return false;
    });
};

export const addNewProduct = (addNewProductFormValues, accessToken) => {
  return axios
    .post(`${BASE_URL}/products`, addNewProductFormValues, {
      headers: { Authorization: `Bearer ${accessToken}` },
    })
    .then((response) => {
      return true;
    })
    .catch((error) => {
      // alert(error);
      return false;
    });
};

export const deleteUser = (userId, accessToken) => {
  return axios
    .delete(`${BASE_URL}/users/${userId}`, {
      headers: { Authorization: `Bearer ${accessToken}` },
    })
    .then((response) => {
      return true;
    })
    .catch((error) => {
      // alert(error);
      return false;
    });
};

export const deleteProduct = (productId, accessToken) => {
  return axios
    .delete(`${BASE_URL}/products/${productId}`, {
      headers: { Authorization: `Bearer ${accessToken}` },
    })
    .then((response) => {
      return true;
    })
    .catch((error) => {
      // alert(error);
      return false;
    });
};

export const getUserDetail = (accessToken, userId) => {
  return axios
    .get(`${BASE_URL}/users/${userId}`, {
      headers: { Authorization: `Bearer ${accessToken}` },
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return false;
    });
};

export const getProductDetail = (accessToken, productId) => {
  return axios
    .get(`${BASE_URL}/products/${productId}`, {
      headers: { Authorization: `Bearer ${accessToken}` },
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return false;
    });
};

export const bookDemo = (bookDemoValues) => {
  return axios
    .post(`${BASE_URL}/enterprise/demoRequest`, bookDemoValues)
    .then((response) => {
      return true;
    })
    .catch((error) => {
      alert("You have already submitted the form! We'll contact you soon!");
      return false;
    });
};

export const getBlogsFromMedium = () => {
  return axios
    .get(
      `https://api.rss2json.com/v1/api.json?rss_url=https://medium.com/feed/@spoofsense`
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return false;
    });
};

export const activateProductTrial = (productId, accessToken) => {
  return axios
    .post(
      `${BASE_URL}/users/activate-demo`,
      { productId: productId },
      {
        headers: { Authorization: `Bearer ${accessToken}` },
      }
    )
    .then((response) => {
      return true;
    })
    .catch((error) => {
      alert(error.response?.data?.message);
      return false;
    });
};

export const updateApiCount = (productId, accessToken) => {
  return axios
    .post(
      `${BASE_URL}/enterprise/updateApiLimit`,
      { productId: productId },
      {
        headers: { Authorization: `Bearer ${accessToken}` },
      }
    )
    .then((response) => {
      return true;
    })
    .catch((error) => {
      alert(error);
      return false;
    });
};
