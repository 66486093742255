import React from "react";
import "./BlogPostContentHeader.css";
import { BsCalendar4Week, BsShare } from "react-icons/bs";
import { RiTimer2Line } from "react-icons/ri";
import { RWebShare } from "react-web-share";
import { capitalizeAndJoin } from "../../../BlogContent/blogContentUtils";

const BlogPostContentHeader = ({ blogInfo }) => {
  const currentURL = window.location.href;
  console.log(blogInfo);
  return (
    <div className="blogpostcontentheader">
      <h1>{blogInfo.title}</h1>
      <hr className="hr-1" />
      <div>
        <div className="blogpostcontentheader-left">
          <div>
            <BsCalendar4Week size={16} />
            <p>{blogInfo.publishedDate}</p>
          </div>
          <div>
            <RiTimer2Line size={17} />
            <p
              style={{ marginLeft: "-.2rem" }}
            >{`${blogInfo.minuteRead} min read`}</p>
          </div>
          <div className="share-btn">
            <RWebShare
              data={{
                text: blogInfo.description,
                url: `${currentURL}`,
                title: blogInfo.title,
              }}
            >
              <div>
                <BsShare size={15} />
                <p>Share</p>
              </div>
            </RWebShare>
          </div>
        </div>
        <div className="blogpostcontentheader-right">
          <div className="share-btn">
            <RWebShare
              data={{
                text: blogInfo.description,
                url: `${currentURL}`,
                title: blogInfo.title,
              }}
            >
              <div>
                <BsShare size={15} />
                <p>Share</p>
              </div>
            </RWebShare>
          </div>
        </div>
      </div>
      <hr className="hr-2" />
      <div>
        <div>
          <h4>Author</h4>
          <p>
            {" "}
            <span>{blogInfo.author}</span> <br /> <span></span>
          </p>
        </div>

        <div>
          <h4>Keywords</h4>
          <p>{capitalizeAndJoin(blogInfo.keywords)}</p>
        </div>

        {blogInfo.imageSources && (
          <div>
            <h4>Images</h4>
            <p>{capitalizeAndJoin(blogInfo.imageSources)}</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default BlogPostContentHeader;
