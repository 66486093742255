import React, { useState } from "react";
import ReactDOM from "react-dom";

import { MdOutlineCancel } from "react-icons/md";
import { deleteProduct } from "../../helpers/helpers";
import { BeatLoader } from "react-spinners";
import useDataLayer from "../../helpers/StateProvider";

import "./DeleteProductModal.css";

const DeleteProductModal = (props) => {
  const productId = props?.productDetails[0]?.value;
  const productName = props?.productDetails[1]?.value;

  const [isLoading, setIsLoading] = useState(false);
  const [{ user }, dispatch] = useDataLayer();

  const deleteProductHandler = async () => {
    if (user) {
      const accessToken = user?.tokens?.access?.token;
      setIsLoading(true);
      const success = await deleteProduct(productId, accessToken);
      success && window.location.reload();
      setIsLoading(false);
    }
  };

  return ReactDOM.createPortal(
    <>
      <div className="deleteProductmodal__overlay"></div>
      <div className="deleteProductmodal">
        <MdOutlineCancel
          onClick={props?.close}
          className="deleteProductmodal__close"
        />
        <h1>You're about to delete a Product </h1>
        <p>{`The Product ${productName} will be permanentaly deleted and all data will be lost`}</p>
        <button
          onClick={deleteProductHandler}
          disabled={isLoading}
          className="deleteProductmodal__save-btn"
        >
          {isLoading ? <BeatLoader size={6} color="white" /> : "Confirm"}
        </button>
      </div>
    </>,
    document.getElementById("portal")
  );
};

export default DeleteProductModal;
