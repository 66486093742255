import React, { useEffect } from "react";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getBlogCard } from "../../../BlogContent/blogContentUtils.js";
import Header from "../../../components/Header/Header";
import BlogPostAuthor from "../BlogPostAuthor/BlogPostAuthor";
import BlogPostContent from "../BlogPostContent/BlogPostContent";
import BlogPostSocial from "../BlogPostSocial/BlogPostSocial";
import "./BlogPost.css";
import { Helmet } from "react-helmet";

const BlogPost = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { blogLink } = useParams();
  const [blogInfo, setBlogInfo] = useState({});

  useEffect(() => {
    setBlogInfo(getBlogCard(blogLink));
  }, [blogLink]);

  const navigate = useNavigate();
  return blogInfo ? (
    <section className="blogpost">
      <Helmet>
        <meta charSet="utf-8" />
        <title>{blogInfo.title}</title>
        <description>{blogInfo.description}</description>
        <meta property="og:title" content={blogInfo.title} />
        <meta property="og:description" content={blogInfo.description} />
        <meta name="twitter:title" content={blogInfo.title} />
        <meta name="twitter:description" content={blogInfo.description} />
        <meta name="twitter:image" content={blogInfo.thumbnail} />
        <meta property="og:image" content={blogInfo.thumbnail} />
      </Helmet>
      <div className="blogpost-headerwrapper">
        <Header isOpen={isOpen} setIsOpen={setIsOpen} OnBlogPost />
      </div>

      <div className="blogpost__body">
        <BlogPostAuthor blogInfo={blogInfo} />
        <BlogPostContent blogInfo={blogInfo} />
        <BlogPostSocial />
      </div>

      <div class={`containerOver ${isOpen && "open"}`}>
        <ul className="containerOver-list">
          <li onClick={() => navigate("/")}>Home</li>
          <li onClick={() => navigate("/blogs")}>Blogs</li>
        </ul>
      </div>
    </section>
  ) : null;
};

export default BlogPost;
