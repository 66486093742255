const validateEmail = (email) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

const isAlpha = /[a-zA-Z]/g;
const isDigit = /[0-9]/;

// SignUp Form Validation
export const signupFormvalidate = ({
  firstName,
  lastName,
  email,
  company,
  password,
  role,
}) => {
  const errors = {};
  if (!firstName.trim().length) errors.firstName = "First Name is Required";

  if (!lastName.trim().length) errors.lastName = "Last Name is Required";

  if (!email.trim().length) errors.email = "Email is Required";
  else if (!validateEmail(email.trim())) errors.email = "Email is not valid";

  if (!password.trim().length) errors.password = "Password is Required";
  else if (password.trim().length < 8)
    errors.password = "Password must be atleast 8 characters";
  else if (!isAlpha.test(password))
    errors.password = "Atleast one alphabet must be present";
  else if (!isDigit.test(password))
    errors.password = "Atleast one digit must be present";

  if (!company.trim().length) errors.company = "Company Name is Required";

  if (!role.trim().length) errors.role = "Role is Required";
  return errors;
};

// SignIn Form Validation
export const signinFormvalidate = ({ email, password }) => {
  const errors = {};
  if (!email.trim().length) errors.email = "Email is Required";

  if (!password.trim().length) errors.password = "Password is Required";
  return errors;
};

export const resetFormvalidate = ({ newPassword, confirmPassword }) => {
  const errors = {};
  if (!newPassword.trim().length)
    errors.newPassword = "New Password is Required";
  else if (newPassword.trim().length < 8)
    errors.newPassword = "Password must be atleast 8 characters";
  else if (!isAlpha.test(newPassword))
    errors.newPassword = "Atleast one alphabet must be present";
  else if (!isDigit.test(newPassword))
    errors.newPassword = "Atleast one digit must be present";

  if (!confirmPassword.trim().length)
    errors.confirmPassword = "Confirm Password is Required";
  else if (confirmPassword !== newPassword)
    errors.confirmPassword = "Password Mismatch";
  return errors;
};

export const editUserFormvalidate = ({
  firstName,
  lastName,
  email,
  company,
  role,
  apiKey,
}) => {
  const errors = {};
  if (!firstName.trim().length) errors.firstName = "First Name is Required";

  if (!lastName.trim().length) errors.lastName = "Last Name is Required";

  if (!email.trim().length) errors.email = "Email is Required";
  else if (!validateEmail(email.trim())) errors.email = "Email is not valid";

  if (!company.trim().length) errors.company = "Company Name is Required";

  if (!role.trim().length) errors.role = "Role is Required";

  if (!apiKey.trim().length) errors.apiKey = "API Key is Required";
  return errors;
};

export const editProductFormvalidate = ({
  productName,
  documentationLink,
  description,
  apiEndpoint,
}) => {
  const errors = {};
  if (!productName.trim().length)
    errors.productName = "Product Name is Required";

  if (!documentationLink.trim().length)
    errors.documentationLink = "Documentation Link is Required";

  if (!description.trim().length)
    errors.productDescription = "Product Description is Required";

  if (!apiEndpoint.trim().length)
    errors.apiEndpoint = "API Endpoint is Required";

  return errors;
};

export const bookDemoFormValidate = ({
  fullName,
  company,
  email,
  message,
  phone,
}) => {
  const errors = {};
  if (!fullName.trim().length) errors.fullName = "Full Name is Required";

  if (!email.trim().length) errors.email = "Email is Required";
  else if (!validateEmail(email.trim())) errors.email = "Email is not valid";

  if (!phone.countryCode.trim().length) errors.phone = "Select the country";
  else if (!phone.phoneNumber.trim().length)
    errors.phone = "Phone number is required";

  if (!company.trim().length) errors.company = "Company Name is Required";

  if (!message.trim().length) errors.message = "Message is Required";
  return errors;
};
