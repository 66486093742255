import React from "react";
import "./HeroVideo.css";
import spoofsenseFaceTitle from "../../../assets/svg/spoofsense_face_title.svg";

const HERO_VIDEO_LINK = "https://www.youtube.com/embed/rfxiZmsq_SI";

const HeroVideo = () => {
  return (
    <section className="herovideo">
      <div className="herovideo__title">
        <h2>Prevent Deepfake fraud with </h2>
        <img src={spoofsenseFaceTitle} alt="spoofsense-face-title" />
      </div>
      <div className="herovideo_place-container">
        <iframe
          src={`${HERO_VIDEO_LINK}?autoplay=1&loop=1&rel=0&controls=0`}
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowfullscreen
          className="herovideo_react-player"
        ></iframe>
      </div>
    </section>
  );
};

export default HeroVideo;
