import React, { useState } from "react";
import ProductCard from "../../components/ProductCard/ProductCard";

import "./Products.css";

import { AiOutlineRight } from "react-icons/ai";
import useDataLayer from "../../helpers/StateProvider";
import { useEffect } from "react";
import { getOwnedProductsList, getProductsList } from "../../helpers/helpers";
import { BounceLoader } from "react-spinners";

import authService from "../../services/auth-service";

const Products = () => {
  const [allProducts, setAllProducts] = useState(false);
  const [allOwnedProducts, setAllOwnedProducts] = useState(false);
  const [{ user }, dispatch] = useDataLayer();

  const refreshTokenJSON = { refreshToken: `${user?.tokens?.refresh?.token}` };

  useEffect(() => {
    const getProducts = async () => {
      if (user && user.tokens) {
        const accessToken = user?.tokens?.access?.token;
        const result = await getProductsList(accessToken);
        if (!result.data) setAllProducts("logout");
        else setAllProducts(result?.data?.results);
      }
    };
    getProducts();
  }, [user]);

  useEffect(() => {
    const getOwnedProducts = async () => {
      if (user && user.tokens) {
        const accessToken = user?.tokens?.access?.token;
        const result = await getOwnedProductsList(accessToken);
        if (!result.data) setAllOwnedProducts([]);
        else setAllOwnedProducts(result?.data);
      }
    };
    getOwnedProducts();
  }, [user]);

  useEffect(() => {
    const checkLogout = async () => {
      if (allProducts === "logout") {
        const success = await authService.logout(refreshTokenJSON);
        success && window.location.reload();
      }
    };
    checkLogout();
  }, [allProducts]);

  return (
    <div className="products">
      <div className="products__view">
        {allProducts && allOwnedProducts ? (
          <>
            {" "}
            <div className="products__view-top">
              <h1>Products Owned</h1>
              {/* <button>
            <span>View All</span>
            <AiOutlineRight />
          </button> */}
            </div>
            <div className="products__view-bottom">
              {!allOwnedProducts.length && (
                <div className="product__view-notActive">
                  <p>You don't have any active product.</p>
                </div>
              )}
              {allOwnedProducts &&
                allOwnedProducts.map((product, index) => (
                  <ProductCard
                    key={index}
                    apiLimit={product.apiLimit}
                    description={product.description}
                    productName={product.productName}
                    documentationLink={product.documentationLink}
                  />
                ))}
            </div>
            <div className="products__view">
              <div className="products__view-top">
                <h1>All Products</h1>
                {/* <button>
            <span>View All</span>
            <AiOutlineRight />
          </button> */}
              </div>
              <div className="products__view-bottom">
                {allProducts &&
                  allProducts.map((product, index) => (
                    <ProductCard
                      key={index}
                      id={product.id}
                      description={product.description}
                      productName={product.productName}
                      documentationLink={product.documentationLink}
                      allProducts
                    />
                  ))}
              </div>
            </div>{" "}
          </>
        ) : (
          <div className="products__loader">
            <BounceLoader color="var(--primary-color)" />
          </div>
        )}
      </div>
    </div>
  );
};

export default Products;
