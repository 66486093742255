import React, { useState } from "react";
import { useDropzone } from "react-dropzone";

import "./UploadImage.css";

import { BiImageAlt } from "react-icons/bi";
import {
  getOwnedProductsList,
  getProductDetail,
  identifySpoof,
  updateApiCount,
} from "../../helpers/helpers";
import useDataLayer from "../../helpers/StateProvider";
import { dashboardContent } from "../../helpers/dashboardContent";
import { useEffect } from "react";

import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import { BeatLoader } from "react-spinners";
import { useNavigate } from "react-router-dom";

const convertBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);

    fileReader.onload = () => {
      resolve(fileReader.result);
    };

    fileReader.onerror = (error) => {
      reject(error);
    };
  });
};

const UploadImage = () => {
  const [img, setImg] = useState([]);
  const [allOwnedProducts, setAllOwnedProducts] = useState(false);
  const [apiLimit, setApiLimit] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [{ user, demoImage, demoLoading }, dispatch] = useDataLayer();
  const { getRootProps, getInputProps, open, acceptedFiles, fileRejections } =
    useDropzone({
      // Disable click and keydown behavior
      noClick: true,
      noKeyboard: true,
      multiple: false,
      accept: "image/jpeg,image/png,image/jpg",
      onDrop: (acceptedFiles) => {
        setImg(
          acceptedFiles.map((file) =>
            Object.assign(file, {
              preview: URL.createObjectURL(file),
            })
          )
        );
      },
    });

  let pathName = window.location.pathname;
  pathName = pathName.substring(1);
  const paths = pathName.split("/");

  const getOwnedProducts = async () => {
    if (user && user.tokens) {
      setIsLoading(true);
      const accessToken = user?.tokens?.access?.token;
      const result = await getOwnedProductsList(accessToken);
      if (!result.data) setAllOwnedProducts([]);
      else setAllOwnedProducts(result?.data);
      setIsLoading(false);
    }
  };

  const navigate = useNavigate();

  useEffect(() => {
    getOwnedProducts();
  }, [user]);

  useEffect(() => {
    if (apiLimit || apiLimit === 0)
      apiLimit > 0 ? navigate("") : navigate("/products");
  }, [apiLimit]);

  useEffect(() => {
    if (allOwnedProducts.length) {
      const result = allOwnedProducts.find(
        ({ productName }) =>
          productName.toLowerCase().replace(/ /g, "") === paths[1]
      );
      setApiLimit(result?.apiLimit);
    }
  }, [allOwnedProducts, apiLimit]);

  const getResponse = async () => {
    dispatch({
      type: "SET_DEMO_LOADING",
      demoLoading: true,
    });
    const result = await convertBase64(acceptedFiles[0]);
    const base64 = result.split(",")[1];
    const apiLink = dashboardContent[paths[1]].apiLink;
    const res = await identifySpoof(apiLink, base64);
    dispatch({
      type: "SET_DEMO_IMAGE",
      demoImage: res?.data,
    });
    dispatch({
      type: "SET_DEMO_LOADING",
      demoLoading: false,
    });
    if (res.data) {
      if (user && user.tokens) {
        const accessToken = user?.tokens?.access?.token;
        const result = await updateApiCount(
          dashboardContent[paths[1]].productId,
          accessToken
        );
        if (result) getOwnedProducts();
      }
    }
  };

  const uploadAgain = () => {
    setImg([]);
    dispatch({
      type: "SET_DEMO_IMAGE",
      demoImage: null,
    });
  };

  const percentage = Math.floor((apiLimit / 25) * 100);

  fileRejections.map(({ file, errors }) =>
    errors.map((e) => {
      alert(e.message);
    })
  );

  return (
    <div className="uploadimage">
      <div className="uploadimage__dropzone">
        <div className="uploadimage__apiProgress">
          {isLoading ? (
            <BeatLoader size={6} color="var(--primary-color)" />
          ) : (
            <>
              <div>
                <CircularProgressbar
                  strokeWidth={15}
                  maxValue={25}
                  value={apiLimit}
                  text={`${percentage}%`}
                  styles={buildStyles({
                    textColor: "var(--primary-color-dark)",
                    pathColor: "var(--primary-color)",
                    trailColor: "#d4d4d4",
                    textSize: "25px",
                    strokeLinecap: "round",
                  })}
                />
              </div>
              <div>
                <h5>API Calls Left</h5>
                <p>{apiLimit} Calls Left</p>
              </div>
            </>
          )}
        </div>
        {img.length ? (
          <>
            <img
              src={img[0]?.preview}
              alt=""
              className="uploadimage__preview-image"
            />
            <div className="uploadimage__dropzone__img-btn">
              {!demoImage && !demoLoading && (
                <button
                  onClick={getResponse}
                  style={{ marginRight: "2rem" }}
                  type="button"
                >
                  Check Liveness
                </button>
              )}
              {!demoLoading && (
                <button
                  disabled={demoLoading}
                  type="button"
                  onClick={uploadAgain}
                >
                  Upload New Image
                </button>
              )}
            </div>
          </>
        ) : (
          <div {...getRootProps({ className: "uploadimage__dropzone_noprev" })}>
            <input {...getInputProps()} />
            <BiImageAlt className="uploadimage__dropzone-icon" />
            <button type="button" onClick={open}>
              Upload Image
            </button>
            <p>Or drop a file</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default UploadImage;
