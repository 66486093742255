import img1 from "../assets/images/blogs/what-is-liveness-detection-explained-in-simple-terms/img-1.webp";
import img2 from "../assets/images/blogs/what-is-liveness-detection-explained-in-simple-terms/img-2.webp";
import { BLOT_CONTENT_UTIL_TYPES } from "./blogContentUtils";

const WHAT_IS_LIVENESS_DETECTION_EXPLAINED_IN_SIMPLE_TERMS = {
  title: "What is Liveness Detection? Explained in simple terms.",
  description:
    "Face recognition systems are becoming increasingly common in banking, healthcare, education, and many more industries.",
  thumbnail: img1,
  link: "what-is-liveness-detection",
  minuteRead: "5",
  publishedDate: "April 6th, 2023",
  author: "Niraimathi",
  keywords: [
    "Passive Liveness Detection",
    "Facial Liveness",
    "Presentation Attack Detection",
  ],
  content: [
    {
      type: BLOT_CONTENT_UTIL_TYPES.IMAGE,
      source: [img1],
    },
    {
      type: BLOT_CONTENT_UTIL_TYPES.HEADING,
      source: ["Overview"],
    },
    {
      type: BLOT_CONTENT_UTIL_TYPES.PARAGRAPH,
      source: [
        "Face recognition systems are becoming increasingly common in banking, healthcare, education, and many more industries. They are deemed as the highest authentication infrastructure as it verifies facial biometrics which is unique for everyone. Be it accessing an account or verifying their profile, facial biometrics is the top priority for many businesses worldwide.",
        "But this authentication system isn’t entirely foolproof. The major flaw of the facial biometric verification system is that it cannot differentiate between a real face from a spoofed subject. This subject can be a paper, digital screen, recorded media, or a mask representing a real face.",
        "With these accessible subjects, fraudsters misuse the above loophole and commit fraud effortlessly. But there is a modern solution to address this glitch.",
      ],
    },
    {
      type: BLOT_CONTENT_UTIL_TYPES.HEADING,
      source: ["What exactly is Liveness Detection?"],
    },
    {
      type: BLOT_CONTENT_UTIL_TYPES.PARAGRAPH,
      source: [
        "Spoofing attacks are very common in ecosystems enabled with facial biometrics. No matter how strong your biometric system is, fraudsters can get through as they mimic your real customer. No, they aren’t any evil twins. Rather they are technically advanced scammers with biological artifacts like 2D or 3D masks, that are similar to your customers’ faces. Even a simple 2d photograph of the customer being held by a spoofer is enough to let a spoofing attack happen.",
        "Is your verification system at fault? No. Your system thinks that it’s your customer trying to log in or authenticate and lets them in.Then happens the fiasco that puts both the customer and your business at risk of losing data and reputation.",
        "There is no doubt that biometric authentications always find whether it’s the right person on the other end. But what if the scanned face comes from a deep fake video or photo of the customer?",
        "Only if the biometric or KYC application focused more on the liveness of the face, such malicious entries could have been caught red-handed. However, it can be hard for it to distinguish a real person from a masked one without an additional technological stack. This is where you should strengthen your facial authentication or verification system with a liveness detection system.",
      ],
    },
    {
      type: BLOT_CONTENT_UTIL_TYPES.BLOCKQUOTE,
      source: [
        "Liveness detection is a verification process that analyzes the liveness of any facial authentication attempt to spot and reject spoof attempts when it arises. When a liveness detection system is incorporated into facial verification biometrics, it can detect if the scanned face is from a real, live person, not an object or screen.",
      ],
    },
    {
      type: BLOT_CONTENT_UTIL_TYPES.PARAGRAPH,
      source: [
        "Liveness answers one of the important questions, ‘Is this a real person?’. By passing every biometric entry through this test, you mitigate malicious presentation attacks and establish trust among your customers. Your customers no longer have to be bothered about their account security getting compromised.",
      ],
    },
    {
      type: BLOT_CONTENT_UTIL_TYPES.HEADING,
      source: ["Liveness detection in a real-world scenario"],
    },
    {
      type: BLOT_CONTENT_UTIL_TYPES.PARAGRAPH,
      source: [
        "Imagine a business trying to onboard one of its important clients. Biometric authentication is crucial here for additional security. Hence, the client attempts to authenticate themselves using the facial recognition software and pose for a selfie. If this software is powered by liveness detection technology, the client’s selfie is instantly analyzed with the help of machine learning algorithms. As it’s clear that it’s a real person from the client’s team, they pass the verification and move to the next stage.",
        "This is what happens in a typical liveness detection process.",
        "Does this mean your customer will spend more time while onboarding themselves? Never. The right liveness detector barely takes 3 to 4 seconds to verify your customer’s scanned image. This method of scanning and analyzing liveness through a picture offers better CX as it’s swift and sharp in producing results.",
        "Alternatively, liveness detection may happen through active liveness authentication where the customer must move their head or make small gestures instead of a selfie. Active liveness can take more time. It often freezes the screen leaving the user annoyed as they have to start again.",
        "Thankfully, there is another type which is passive liveness detection. This is gaining more popularity as it doesn’t require any additional action from your customers like blinking or smiling. The verification process happens in the background and moves to the next step right away.",
      ],
    },
    {
      type: BLOT_CONTENT_UTIL_TYPES.BLOCKQUOTE,
      source: [
        "With passive liveness detection, you can render a friction-free, smooth, and secure onboarding or verification process that requires minimal effort.",
      ],
    },
    {
      type: BLOT_CONTENT_UTIL_TYPES.HEADING,
      source: ["Why is Liveness Detection needed in today’s world?"],
    },
    {
      type: BLOT_CONTENT_UTIL_TYPES.IMAGE,
      source: [img2],
    },
    {
      type: BLOT_CONTENT_UTIL_TYPES.PARAGRAPH,
      source: [
        "In today’s world, one can get hold of anyone’s picture or video from social media networks. Even if someone’s digital footprints are private, there are morphing and deep fake technologies available everywhere. This fact itself is alarming as anyone can access your account and bypass biometric authentication by showing your picture.",
        "From social media to public forums to banking platforms, it’s easier to pretend like someone else and make your way in. But the outcome can be dangerous when this impersonation happens in important platforms dealing with sensitive data.",
        "If you are a business owner, this is the cue for you to investigate your facial verification processes, no matter where it’s set up and what data it leads to.",
        "Besides, there is widespread awareness of measures customers must follow while maintaining digital assets. At the same time, there is too little information about how things can go wrong on the other side of this.",
        "For this very reason, we had to upgrade from basic factors to two-factor authentications which are now topped with biometric specimens.",
        "Yet, this is not enough. Robust technologies must be in place to strengthen this wall of protection. Liveness detection is one of these must-have technical stack. You can opt for plug-and-connect types of liveness detection SDKs for smooth integration with your biometric application.",
      ],
    },
    {
      type: BLOT_CONTENT_UTIL_TYPES.BLOCKQUOTE,
      source: [
        "If giving secure and private access to your customer is your top priority, you must adopt such strong security measures. We might have come far across from phishing emails or scary desktop popups.",
      ],
    },
    {
      type: BLOT_CONTENT_UTIL_TYPES.PARAGRAPH,
      source: [
        "If you are looking to integrate facial liveness detection for your use-case, checkout <a href='https://spoofsense.ai/' target='__blank'>Spoofsense</a> Passive Liveness APIs and SDKs.",
      ],
    },
  ],
};

export default WHAT_IS_LIVENESS_DETECTION_EXPLAINED_IN_SIMPLE_TERMS;
