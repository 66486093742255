import axios from "axios";
import { AUTH_API_URL } from "../helpers/helpers";

const signup = (signupFormValues) => {
  return axios
    .post(AUTH_API_URL + "/register", signupFormValues)
    .then((response) => {
      localStorage.setItem("userToken", JSON.stringify(response.data));
      return true;
    })
    .catch((error) => {
      alert("Email Already Taken!");
      return false;
    });
};

const signin = (signinFormValues) => {
  return axios
    .post(AUTH_API_URL + "/login", signinFormValues)
    .then((response) => {
      localStorage.setItem("userToken", JSON.stringify(response.data));
      return true;
    })
    .catch((error) => {
      alert("Invalid Email or Password!");
      return false;
    });
};

const logout = (refreshTokenJSON) => {
  return axios
    .post(AUTH_API_URL + "/logout", refreshTokenJSON)
    .then((response) => {
      localStorage.removeItem("userToken");
      return true;
    })
    .catch((error) => {
      alert("Account Not Found!");
      return false;
    });
};

const getCurrentUser = () => {
  return JSON.parse(localStorage.getItem("userToken"));
};

const authService = {
  signup,
  signin,
  logout,
  getCurrentUser,
};

export default authService;
