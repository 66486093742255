import React from "react";

import "./PageDescription.css";

const PageDescription = ({ title = "Title", subTitle = "SubTitle" }) => {
  return (
    <div className="pagedescription">
      <h1>{title}</h1>
      <p>{subTitle}</p>
    </div>
  );
};

export default PageDescription;
