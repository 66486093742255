import React from "react";
import "./InfoCards.css";

const CARDS_DATA = [
  {
    headingLines: ["Innovative Injection", "Attack Detection:"],
    description:
      "Detects injection based spoofing done using virtual cameras, video injections, deepfake-injections.",
  },
  {
    headingLines: ["Versatile Attack", "Detection:"],
    description:
      "Recognizes and blocks a wide range of attack vectors, from printed images, video replays, cutout-masks, 3D Silicone masks, recorded videos to live streams.",
  },
  {
    headingLines: ["Effectively counters", "Deepfakes:"],
    description:
      "Detects renderings, face swaps, and morphing attacks, Faces created using GenAI algorithm, etc.",
  },
  {
    headingLines: ["Enhanced Detection", "Performance:"],
    description:
      "SpoofSense continuously improves its detection algorithms, ensuring top-tier performance in presentation attack detection.",
  },
];

const InfoCards = () => {
  return (
    <div className="wrapper_info-card-container">
      <div className="wrapper_info-cards">
        {CARDS_DATA?.map((cardData, index) => (
          <div key={index} className="wrapper__info-card">
            <div>
              {cardData?.headingLines?.map((line, indexI) => (
                <h1 key={indexI}>{line}</h1>
              ))}
            </div>
            <p>{cardData.description}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default InfoCards;
