import ReactDOM from "react-dom";
import React, { useEffect, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";

import "./BookDemo.css";

import { MdOutlineCancel } from "react-icons/md";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

import vid2 from "../../assets/videos/vid2.webm";
import img2 from "../../assets/images/for safari2.webp";

import { bookDemoFormValidate } from "../../helpers/validation";
import { bookDemo } from "../../helpers/helpers";
import { BeatLoader } from "react-spinners";

export const timer = (delay) => new Promise((res) => setTimeout(res, delay));

const BookDemo = ({ close }) => {
  const [bookDemoValues, setBookDemoValues] = useState({
    fullName: "",
    phone: {
      countryCode: "91",
      phoneNumber: "",
    },
    company: "",
    email: "",
    message: "",
  });

  const [buttonValue, setButtonValue] = useState("Send Message");
  const [bookDemoFormErrors, setBookDemoFormErrors] = useState({});
  const [bookDemoIsSubmit, setBookDemoIsSubmit] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isVerified, setIsVerified] = useState(false);
  const [verifyError, setVerifyError] = useState(false);

  const bookDemoInputHandler = (event) => {
    const { name, value } = event.target;
    setBookDemoValues({ ...bookDemoValues, [name]: value });
  };

  const phoneHandler = (value, country, formattedValue) => {
    const countryCode = country.dialCode;
    const phoneNumber = value.slice(countryCode.length);
    setBookDemoValues({
      ...bookDemoValues,
      phone: { countryCode: countryCode, phoneNumber: phoneNumber },
    });
  };

  const bookDemoSubmitHandler = (event) => {
    event.preventDefault();
    setBookDemoFormErrors(bookDemoFormValidate(bookDemoValues));
    setBookDemoIsSubmit(true);
  };

  useEffect(() => {
    const bookDemoMail = async () => {
      if (!Object.keys(bookDemoFormErrors).length && bookDemoIsSubmit) {
        if (!isVerified) {
          setVerifyError("Verify that you are not a robot");
          return;
        } else {
          setVerifyError(false);
        }
        setIsLoading(true);
        const success = await bookDemo(bookDemoValues);
        success && setButtonValue("Thank you");
        setIsLoading(false);
        success && (await timer(1500));
        setButtonValue("Send Message");
        setBookDemoValues({
          fullName: "",
          phone: {
            countryCode: "",
            phoneNumber: "",
          },
          company: "",
          email: "",
          message: "",
        });
      }
    };
    bookDemoMail();
  }, [bookDemoFormErrors, bookDemoIsSubmit]);

  const onChange = (value) => {
    setIsVerified(true);
  };

  return ReactDOM.createPortal(
    <>
      <div className="bookdemo__overlay"></div>
      <div className="bookdemo">
        <MdOutlineCancel onClick={close} className="bookdemo__close" />
        <div className="bookdemo__left">
          <h2>Book a Demo</h2>
          <h1>Let's get in touch.</h1>
          {/* <div className="bookdemo__left-contact">
            <a href="mailto:kartikeya@spoofsense.com">
              kartikeya@spoofsense.com
            </a>
            <a href="tel:+919963896518">+91 9963896518</a>
          </div> */}
          <form action="" className="bookdemo__left-form">
            <div>
              <label htmlFor="fullname">Full Name</label>
              <input
                type="text"
                name="fullName"
                onChange={bookDemoInputHandler}
                value={bookDemoValues.fullName}
              />
              <p className="bookDemo__form-error">
                {bookDemoFormErrors?.fullName}
              </p>
            </div>
            <div>
              <label htmlFor="phone">Phone</label>
              <PhoneInput
                type="number"
                name="phone"
                country={"in"}
                onChange={phoneHandler}
                placeholder=""
                value={
                  bookDemoValues.phone.countryCode +
                  bookDemoValues.phone.phoneNumber
                }
                style={{ width: "1px" }}
                inputClass="phoneInput"
              />
              <p className="bookDemo__form-error">
                {bookDemoFormErrors?.phone}
              </p>
            </div>
            <div>
              <label htmlFor="company">Company</label>
              <input
                type="text"
                name="company"
                onChange={bookDemoInputHandler}
                value={bookDemoValues.company}
              />
              <p className="bookDemo__form-error">
                {bookDemoFormErrors?.company}
              </p>
            </div>
            <div>
              <label htmlFor="email">Email</label>
              <input
                type="email"
                name="email"
                onChange={bookDemoInputHandler}
                value={bookDemoValues.email}
              />
              <p className="bookDemo__form-error">
                {bookDemoFormErrors?.email}
              </p>
            </div>
            <div>
              <label htmlFor="message">Message</label>
              <textarea
                type="text"
                name="message"
                onChange={bookDemoInputHandler}
                style={{ fontFamily: "var(--body-font)" }}
                value={bookDemoValues.message}
              />
              <p className="bookDemo__form-error">
                {bookDemoFormErrors?.message}
              </p>
            </div>
          </form>

          <div style={{ transform: "scale(0.85)", transformOrigin: "0 -3rem" }}>
            <ReCAPTCHA
              sitekey="6LcA_BAgAAAAAMCGwjheLT0nvQ1ZPtW5XtlsaITj"
              onChange={onChange}
            />
            {verifyError && (
              <p className="bookDemo__form-error">{verifyError}</p>
            )}
          </div>

          <button
            style={
              buttonValue === "Thank you" ? { backgroundColor: "green" } : {}
            }
            disabled={isLoading}
            onClick={bookDemoSubmitHandler}
          >
            {isLoading ? <BeatLoader size={6} color="white" /> : buttonValue}
          </button>
        </div>
        <div className="bookdemo__right">
          {!window.navigator.platform.match(/^iPhone/) &&
          !window.navigator.platform.match(/^Mac/) ? (
            <video autoPlay loop muted playsInline className="video">
              <source src={vid2} type="video/webm" />
            </video>
          ) : (
            <img src={img2} alt="spoofsense" />
          )}
        </div>
      </div>
    </>,
    document.getElementById("portal")
  );
};

export default BookDemo;
