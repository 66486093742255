import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { signupFormvalidate } from "../../helpers/validation";
import authService from "../../services/auth-service";

// Components
import MobileIllustration from "../../containers/MobileIllustration/MobileIllustration";

// Styles
import "./SignUp.css";

// Assets
import { BsFileBarGraph } from "react-icons/bs";
import { BiShow, BiHide } from "react-icons/bi";
import { FiLoader } from "react-icons/fi";
import useDataLayer from "../../helpers/StateProvider";
import logo from "../../assets/images/logo.webp";
import { BeatLoader } from "react-spinners";

const SignUp = () => {
  const [signupFormValues, setSignupFormValues] = useState({
    firstName: "",
    lastName: "",
    email: "",
    company: "",
    password: "",
    role: "",
  });

  const [signupFormErrors, setSignupFormErrors] = useState({});
  const [signupIsSubmit, setSignupIsSubmit] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [{ user }, dispatch] = useDataLayer();

  const navigate = useNavigate();

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const signupInputHandler = (event) => {
    const { name, value } = event.target;
    setSignupFormValues({ ...signupFormValues, [name]: value });
  };

  const signupSubmitHandler = (event) => {
    event.preventDefault();
    setSignupFormErrors(signupFormvalidate(signupFormValues));
    setSignupIsSubmit(true);
  };

  useEffect(() => {
    user ? navigate("/products") : navigate("/signup");
  }, [user]);

  useEffect(() => {
    const setUser = async () => {
      if (!Object.keys(signupFormErrors).length && signupIsSubmit) {
        setIsLoading(true);
        const success = await authService.signup(signupFormValues);
        success && window.location.reload();
        setIsLoading(false);
      }
    };
    setUser();
  }, [signupFormErrors, signupIsSubmit]);

  return (
    <div className="signup">
      <div className="signup__form">
        <div className="signup__form-logo">
          {/* <BsFileBarGraph className="signup__form-logo-icon" />
          <h2>SpoofSense</h2> */}
          <img
            src={logo}
            alt="spoofsense_logo"
            className="signup__form-logo-img"
          />
        </div>

        <div className="signup__form-body">
          <div className="signup__form-body__head">
            <h1 className="signup__form-body__head-title">Sign Up</h1>
            <h4 className="signup__form-body__head-subtitle">
              Sign up to SpoofSense
            </h4>
          </div>
          <form
            onSubmit={signupSubmitHandler}
            className="signup__form-body__main"
          >
            <div className="signup__form-body__main-name">
              <div className="signup__form__input-container">
                <label className="signup__input-text" htmlFor="firstName">
                  First Name
                </label>
                <input
                  className={`signup__input-box ${
                    signupFormErrors.firstName && "signup__input-error"
                  }`}
                  type="text"
                  name="firstName"
                  onChange={signupInputHandler}
                  value={signupFormValues.firstName}
                />
                <p className="signup__form-error">
                  {signupFormErrors?.firstName}
                </p>
              </div>
              <div className="signup__form__input-container">
                <label className="signup__input-text" htmlFor="lastName">
                  Last Name
                </label>
                <input
                  className={`signup__input-box ${
                    signupFormErrors.lastName && "signup__input-error"
                  }`}
                  type="text"
                  name="lastName"
                  onChange={signupInputHandler}
                  value={signupFormValues.lastName}
                />
                <p className="signup__form-error">
                  {signupFormErrors?.lastName}
                </p>
              </div>
            </div>
            <div className="signup__form__input-container">
              <label className="signup__input-text" htmlFor="email">
                Enter your Email
              </label>
              <input
                className={`signup__input-box ${
                  signupFormErrors.email && "signup__input-error"
                }`}
                placeholder="name@example.com"
                type="email"
                name="email"
                onChange={signupInputHandler}
                value={signupFormValues.email}
              />
              <p className="signup__form-error">{signupFormErrors?.email}</p>
            </div>
            <div className="signup__form__input-container">
              <label className="signup__input-text" htmlFor="company">
                Enter your Company
              </label>
              <input
                className={`signup__input-box ${
                  signupFormErrors.company && "signup__input-error"
                }`}
                placeholder="eg. SpoofSense"
                type="text"
                name="company"
                onChange={signupInputHandler}
                value={signupFormValues.company}
              />
              <p className="signup__form-error">{signupFormErrors?.company}</p>
            </div>
            <div className="signup__form__input-container">
              <label className="signup__input-text" htmlFor="role">
                I am a
              </label>
              <select
                className={`signup__input-box signup__input-select ${
                  signupFormErrors.role && "signup__input-error"
                }`}
                name="role"
                defaultValue={"DEFAULT"}
                onChange={signupInputHandler}
              >
                <option value="DEFAULT" disabled hidden>
                  Select a role
                </option>
                <option value="developer">Developer</option>
                <option value="enterpriseAdmin">Enterprise</option>
              </select>
              <p className="signup__form-error">{signupFormErrors?.role}</p>
            </div>
            <div className="signup__form__input-container">
              <label className="signup__input-text" htmlFor="password">
                Create your Password
              </label>
              <input
                className={`signup__input-box signup__pwd__input-box ${
                  signupFormErrors.password && "signup__input-error"
                }`}
                placeholder="atleast 8 characters"
                type={showPassword ? "text" : "password"}
                name="password"
                onChange={signupInputHandler}
                value={signupFormValues.password}
              />
              <p className="signup__form-error">{signupFormErrors?.password}</p>
              {showPassword ? (
                <BiShow
                  onClick={toggleShowPassword}
                  className="signup__pwd-eye"
                />
              ) : (
                <BiHide
                  onClick={toggleShowPassword}
                  className="signup__pwd-eye"
                />
              )}
            </div>
            <button
              disabled={isLoading}
              type="submit"
              className="signup__form__btn"
            >
              {isLoading ? <BeatLoader size={6} color="white" /> : "Sign Up"}
            </button>
          </form>
          <div className="signup__form-body__footer">
            <hr />
            <p>Already have an account?</p>
            <button onClick={() => navigate("/signin")}>Sign In</button>
          </div>
        </div>
      </div>

      <MobileIllustration />
    </div>
  );
};

export default SignUp;
