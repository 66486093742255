import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";

import "./EditProductModal.css";

import { MdOutlineCancel } from "react-icons/md";
import { FaProductHunt } from "react-icons/fa";
import {
  editProductFormvalidate,
  editUserFormvalidate,
} from "../../helpers/validation";
import useDataLayer from "../../helpers/StateProvider";
import { editProduct, editUser } from "../../helpers/helpers";
import { BeatLoader } from "react-spinners";

const EditProductModal = (props) => {
  const productId = props?.productDetails[0]?.value;
  const productName = props?.productDetails[1]?.value;
  const documentationLink = props?.productDetails[2]?.value;
  const productDescription = props?.productDetails[3]?.value;
  const apiEndpoint = props?.productDetails[4]?.value;

  const [editProductFormValues, setEditProductFormValues] = useState({
    productName: productName,
    documentationLink: documentationLink,
    description: productDescription,
    apiEndpoint: apiEndpoint,
  });
  const [editProductFormErrors, setEditProductFormErrors] = useState({});
  const [editProductIsSubmit, setEditProductIsSubmit] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const editProductInputHandler = (event) => {
    const { name, value } = event.target;
    setEditProductFormValues({ ...editProductFormValues, [name]: value });
  };

  const submitHandler = (event) => {
    event.preventDefault();
    setEditProductFormErrors(editProductFormvalidate(editProductFormValues));
    setEditProductIsSubmit(true);
  };

  const [{ user }, dispatch] = useDataLayer();

  useEffect(() => {
    const setProduct = async () => {
      if (!Object.keys(editProductFormErrors).length && editProductIsSubmit) {
        if (user) {
          const accessToken = user?.tokens?.access?.token;
          setIsLoading(true);
          const success = await editProduct(
            accessToken,
            productId,
            editProductFormValues
          );
          success && window.location.reload();
          setIsLoading(false);
        }
      }
    };
    setProduct();
  }, [editProductFormErrors, editProductIsSubmit]);

  return ReactDOM.createPortal(
    <>
      <div className="editProductmodal__overlay"></div>
      <div className="editProductmodal">
        <MdOutlineCancel
          onClick={props?.close}
          className="editProductmodal__close"
        />
        <h1 className="editProductmodal__title">Edit Product</h1>
        <div className="editProductmodal__profile">
          <FaProductHunt style={{ fontSize: "3.8rem" }} />
          <div className="editProductmodal__profile-right">
            <h2>{productName}</h2>
          </div>
        </div>
        <form onSubmit={submitHandler} className="editProductmodal__form">
          <div className="editProductmodal__form-top">
            <div className="editProductmodal__form-container">
              <label htmlFor="productName">Product Name</label>
              <input
                value={editProductFormValues.productName}
                name="productName"
                type="text"
                onChange={editProductInputHandler}
              />
              <p className="editProduct__form-error">
                {editProductFormErrors?.productName}
              </p>
            </div>
            <div className="editProductmodal__form-container">
              <label htmlFor="documentationLink">Documentation Link</label>
              <input
                value={editProductFormValues.documentationLink}
                name="documentationLink"
                type="text"
                onChange={editProductInputHandler}
              />
              <p className="editProduct__form-error">
                {editProductFormErrors?.documentationLink}
              </p>
            </div>
          </div>
          <div className="editProductmodal__form-center">
            <div className="editProductmodal__form-container">
              <label htmlFor="description">Product Description</label>
              <input
                value={editProductFormValues.description}
                name="description"
                type="text"
                onChange={editProductInputHandler}
              />
              <p className="editProduct__form-error">
                {editProductFormErrors?.productDescription}
              </p>
            </div>
            <div className="editProductmodal__form-container">
              <label htmlFor="apiEndpoint">API Endpoint</label>
              <input
                value={editProductFormValues.apiEndpoint}
                name="apiEndpoint"
                type="text"
                onChange={editProductInputHandler}
              />
              <p className="editProduct__form-error">
                {editProductFormErrors?.apiEndpoint}
              </p>
            </div>
          </div>
          <button
            disabled={isLoading}
            type="submit"
            className="editProductmodal__save-btn"
          >
            {isLoading ? <BeatLoader size={6} color="white" /> : "Save"}
          </button>
        </form>
      </div>
    </>,
    document.getElementById("portal")
  );
};

export default EditProductModal;
