import React, { useEffect, useState } from "react";

import "./ResetPassword.css";

import { FaUserCircle } from "react-icons/fa";
import logo from "../../assets/images/logo.webp";
import { resetFormvalidate } from "../../helpers/validation";
import { useLocation } from "react-router-dom";
import { BeatLoader } from "react-spinners";
import { resetPasswordResponse } from "../../helpers/helpers";

const ResetPassword = () => {
  const [showLoader, setShowLoader] = useState(false);
  const [resetFormErrors, setResetFormErrors] = useState({});
  const [resetIsSubmit, setResetIsSubmit] = useState(false);
  const [resetFormValues, setResetFormValues] = useState({
    newPassword: "",
    confirmPassword: "",
  });

  const resetInputHandler = (event) => {
    const { name, value } = event.target;
    setResetFormValues({ ...resetFormValues, [name]: value });
  };

  const { search } = useLocation();

  const resetSubmitHandler = (event) => {
    event.preventDefault();
    setResetFormErrors(resetFormvalidate(resetFormValues));
    setResetIsSubmit(true);
  };

  useEffect(() => {
    const resetPassword = async () => {
      if (!Object.keys(resetFormErrors).length && resetIsSubmit) {
        setShowLoader(true);
        const urlParams = Object.fromEntries([...new URLSearchParams(search)]);
        const success = await resetPasswordResponse(
          urlParams,
          resetFormValues.newPassword
        );
        setShowLoader(false);
        success && alert("Password Changed Successfully");
      }
    };
    resetPassword();
  }, [resetFormErrors, resetIsSubmit]);

  return (
    <div className="resetpassword">
      <img
        src={logo}
        alt="spoofsense_logo"
        className="resetpassword__form-logo"
      />
      <h3 className="resetpassword__title">Reset Your Password</h3>
      <div className="resetpassword__profile">
        <FaUserCircle style={{ fontSize: "3.5rem" }} />
        <div className="resetpassword__profle-user">
          <h4>Aaveg Gupta</h4>
          <p>aaveg@spoofsense.com</p>
        </div>
      </div>
      <form onSubmit={resetSubmitHandler} className="resetpassword__form">
        <div className="resetpassword__form__input-container">
          <label htmlFor="newPassword" className="resetpassword__form-label">
            New Password
          </label>
          <input
            type="text"
            name="newPassword"
            placeholder="Create your password"
            className={`resetpassword__form-input ${
              resetFormErrors.newPassword && "resetpassword__input-error"
            }`}
            value={resetFormValues.newPassword}
            onChange={resetInputHandler}
          />
          <p className="resetpassword__form-error">
            {resetFormErrors?.newPassword}
          </p>
        </div>
        <div className="resetpassword__form__input-container">
          <label
            htmlFor="confirmPassword"
            className="resetpassword__form-label"
          >
            Confirm Password
          </label>
          <input
            type="text"
            name="confirmPassword"
            placeholder="Confirm your password"
            className={`resetpassword__form-input ${
              resetFormErrors.confirmPassword && "resetpassword__input-error"
            }`}
            value={resetFormValues.confirmPassword}
            onChange={resetInputHandler}
          />
          <p className="resetpassword__form-error">
            {resetFormErrors?.confirmPassword}
          </p>
        </div>
        <button type="submit" className="resetpassword__form-btn">
          {showLoader ? <BeatLoader size={6} color="white" /> : "Save"}
        </button>
      </form>
    </div>
  );
};

export default ResetPassword;
