import React from "react";

import "./Header.css";

import spoofsenseLogo from "../../assets/svg/SpoofSense.svg";

import { useNavigate } from "react-router-dom";

const Header = ({
  setIsOpen,
  isOpen,
  setShowBookDemo,
  OnBlogPost,
  OnBlogList,
}) => {
  let navigate = useNavigate();
  return (
    <div className="header">
      <div className="header__left">
        <img src={spoofsenseLogo} alt="spoofsense_logo" />
      </div>

      <div className="header__right">
        {OnBlogPost || OnBlogList ? (
          <li
            onClick={() => navigate("/")}
            style={{ color: "white", listStyleType: "none" }}
          >
            Home
          </li>
        ) : (
          <a
            href="https://spoofsense.gitbook.io/spoofsense-developer-documentation/"
            target="__blank"
          >
            <li style={{ color: "white", listStyleType: "none" }}>
              Documentation
            </li>
          </a>
        )}

        {!OnBlogList && (
          <li
            onClick={() => navigate("/blogs")}
            style={{ color: "white", listStyleType: "none" }}
          >
            Blogs
          </li>
        )}

        {!OnBlogPost && !OnBlogList && (
          <a
            href="https://cal.com/kartikeya-bhardwaj-spoofsense/30min"
            target="__blank"
          >
            <button>Book Demo</button>
          </a>
        )}
      </div>
      <div
        onClick={() => setIsOpen(!isOpen)}
        className={`header__right-mob ${isOpen && "open"}`}
      >
        <div className="header__right-mob__ham"></div>
      </div>
    </div>
  );
};

export default Header;
