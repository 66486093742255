import React from "react";
import SandboxKeyCard from "../../components/SandboxKeyCard/SandboxKeyCard";
import UserDetailsCard from "../../components/UserDetailsCard/UserDetailsCard";
import "./Settings.css";

const Settings = () => {
  return (
    <div className="settings">
      <UserDetailsCard />
      {/* <SandboxKeyCard /> */}
    </div>
  );
};

export default Settings;
