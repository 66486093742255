import React, { useEffect, useState } from "react";
import { CopyBlock, dracula } from "react-code-blocks";
import codeSnippets from "../../helpers/codeSnippets";

import "./CodeSwitcher.css";

const CodeSwitcher = () => {
  const [activeButton, setActiveButton] = useState([
    true,
    ...Array(codeSnippets.length - 1).fill(false),
  ]);

  const handleActiveButton = (index) => {
    setActiveButton((prevState) =>
      prevState.map((item, idx) => (idx === index ? true : false))
    );
  };

  return (
    <div className="codeswitcher">
      <h2>Copy your Snippets</h2>
      <div className="codeswitcher__languages">
        {codeSnippets.map((snippet, index) => (
          <button
            key={index}
            className={`codeswitcher__btn ${
              activeButton[index] && "codeswitcher__btn-active"
            }`}
            onClick={() => handleActiveButton(index)}
          >
            {snippet.title}
          </button>
        ))}
      </div>
      <div className="codeswitcher__code">
        {codeSnippets.map(
          (snippet, index) =>
            activeButton[index] && (
              <CopyBlock
                key={index}
                text={snippet.code}
                language={snippet.language}
                theme={dracula}
                wrapLongLines={false}
                showLineNumbers
                customStyle={{
                  height: "30rem",
                  overflowY: "scroll",
                  fontSize: ".9rem",
                }}
              />
            )
        )}
      </div>
    </div>
  );
};

export default CodeSwitcher;
