import { BsCodeSlash, BsFileImage } from "react-icons/bs";
import { RiArrowUpSFill, RiArrowDownSFill } from "react-icons/ri";
import { AiOutlineLink, AiFillSetting } from "react-icons/ai";
import { IoIosDocument } from "react-icons/io";
import { FaUser } from "react-icons/fa";
import { GrProductHunt } from "react-icons/gr";

const sidebarContent = [
  {
    icon: BsFileImage,
    text: "Demo",
    route: "/demo",
    forAdmin: false,
    forProduct: false,
  },
  {
    icon: AiOutlineLink,
    text: "Integration",
    route: "/integration",
    forAdmin: false,
    forProduct: false,
  },
  {
    icon: BsCodeSlash,
    text: "Developers",
    iconUp: RiArrowUpSFill,
    iconDown: RiArrowDownSFill,
    subNav: [
      {
        icon: IoIosDocument,
        text: "Documentation",
        route: "/documentation",
      },
    ],
    forAdmin: false,
    forProduct: false,
  },
  {
    icon: GrProductHunt,
    text: "Products",
    route: "/products",
    forAdmin: false,
    forProduct: true,
  },
  {
    icon: AiFillSetting,
    text: "Settings",
    route: "/settings",
    forAdmin: false,
    forProduct: true,
  },
  {
    icon: FaUser,
    text: "Manage Users",
    route: "/manageusers",
    forAdmin: true,
    forProduct: false,
  },
  {
    icon: GrProductHunt,
    text: "Manage Products",
    route: "/manageproducts",
    forAdmin: true,
    forProduct: false,
  },
];

export default sidebarContent;
