import React from "react";

// Styles
import "./MobileIllustration.css";

// Assets
import spoofsenseInfo from "../../assets/images/spoofsenseInfo.webp";

const MobileIllustration = () => {
  return (
    <div className="mobile-illustration">
      <img src={spoofsenseInfo} alt="character_with_mobile" />
    </div>
  );
};

export default MobileIllustration;
