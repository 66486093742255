import React, { useEffect, useMemo, useState } from "react";
import { useTable, usePagination } from "react-table";

import "./ProductAdmin.css";

import { FaProductHunt } from "react-icons/fa";
import { BiSearch } from "react-icons/bi";
import { FiFilter } from "react-icons/fi";
import {
  AiOutlinePlus,
  AiOutlineDoubleLeft,
  AiOutlineLeft,
  AiOutlineDoubleRight,
  AiOutlineRight,
} from "react-icons/ai";
import useDataLayer from "../../helpers/StateProvider";
import {
  getNewTokens,
  getProductsList,
  getUserList,
  isAccessExpires,
} from "../../helpers/helpers";
import EditUserModal from "../../containers/EditUserModal/EditUserModal";
import { BounceLoader } from "react-spinners";
import AddNewUserModal from "../../containers/AddNewUserModal/AddNewUserModal";
import DeleteUserModal from "../../containers/DeleteUserModal/DeleteUserModal";
import EditProductModal from "../../containers/EditProductModal/EditProductModal";
import DeleteProductModal from "../../containers/DeleteProductModal/DeleteProductModal";
import AddNewProductModal from "../../containers/AddNewProductModel/AddNewProductModel";

const tableColumn = [
  {
    Header: "S.No.",
    accessor: "id",
  },
  {
    Header: "Product Name",
    accessor: "productName",
  },
  {
    Header: "Documentation Link",
    accessor: "documentationLink",
    Cell: ({ row }) => (
      <a target="__blank" href={row.values.documentationLink}>
        Click Here
      </a>
    ),
  },
  {
    Header: "Description",
    accessor: "description",
  },
  {
    Header: "API Endpoint",
    accessor: "apiEndpoint",
  },
];

const ProductAdmin = () => {
  const [{ user }, dispatch] = useDataLayer();
  const [productList, setProductList] = useState([]);
  const [productDetails, setProductDetails] = useState(false);
  const [showAddNewProduct, setShowAddNewProduct] = useState(false);
  const [deleteProduct, setDeleteProduct] = useState(false);

  const columns = useMemo(() => tableColumn, []);
  const data = useMemo(() => productList, [productList]);
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    gotoPage,
    nextPage,
    previousPage,
    pageCount,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns: columns,
      data: data,
      initialState: { pageIndex: 0 },
    },
    usePagination
  );

  useEffect(() => {
    setPageSize(5);
  }, []);

  useEffect(() => {
    const getUsersList = async () => {
      if (user && user.tokens) {
        const refreshToken = user?.tokens?.refresh?.token;
        const accessToken = user?.tokens?.access?.token;
        const usersList = await getProductsList(accessToken);
        if (usersList) {
          setProductList(usersList?.data?.results);
        } else {
          const tokens = await getNewTokens(refreshToken);
          if (tokens) {
            dispatch({ type: "SET_NEW_TOKENS", tokens: tokens });
            localStorage.setItem("userToken", JSON.stringify(user));
          }
          // getUsersList();
        }
      }

      // const refreshToken = user?.tokens?.refresh?.token;
      // const accessToken = user?.tokens?.access?.token;
      // const usersList = await getUserList(accessToken, refreshToken);
      // if (!usersList) {
      //   const tokens = await getNewTokens(refreshToken);
      //   dispatch({ type: "SET_NEW_TOKENS", tokens: tokens });
      //   localStorage.setItem("userToken", JSON.stringify(user));
      //   getUsersList();
      // } else {
      //   setUserList(usersList?.data?.results);
      // }
    };
    getUsersList();
  }, [user]);

  return (
    <>
      <div className="ProductAdmin">
        <div className="ProductAdmin__title">
          <FaProductHunt />
          <h1>Products</h1>
        </div>
        <div className="ProductAdmin__utils">
          <div className="ProductAdmin__utils-search">
            <BiSearch className="ProductAdmin__utils-search-icon" />
            <input
              type="text"
              placeholder="Search for a product"
              className="ProductAdmin__utils-search-input"
            />
          </div>
          <div className="ProductAdmin__utils-btns">
            <button className="ProductAdmin__utils-btn--filter">
              <FiFilter style={{ fontSize: "1rem", marginRight: ".2rem" }} />
              <p>Filters</p>
            </button>
            <button
              onClick={() => setShowAddNewProduct(true)}
              className="ProductAdmin__utils-btn--add"
            >
              <AiOutlinePlus
                style={{ fontSize: "1rem", marginRight: ".2rem" }}
              />
              Add New Product
            </button>
          </div>
        </div>
        {!productList.length ? (
          <div className="ProductAdmin__loader">
            <BounceLoader color="var(--primary-color)" />
          </div>
        ) : (
          <>
            <table {...getTableProps()} className="ProductAdmin__table">
              <thead>
                {headerGroups.map((headerGroup) => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column) => (
                      <th {...column.getHeaderProps()}>
                        {column.render("Header")}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()}>
                {page.map((row, i) => {
                  prepareRow(row);
                  return (
                    <tr {...row.getRowProps()}>
                      {row.cells.map((cell, j) => {
                        if (j <= 5) {
                          return (
                            <td {...cell.getCellProps()}>
                              {j === 0 ? i + 1 : cell.render("Cell")}
                            </td>
                          );
                        }
                      })}
                      <td>
                        <button
                          onClick={() => setProductDetails(row.cells)}
                          className="ProductAdmin__table-btn"
                        >
                          Edit
                        </button>
                      </td>
                      <td>
                        <button
                          onClick={() => setDeleteProduct(row.cells)}
                          className="ProductAdmin__table-btn"
                        >
                          Delete
                        </button>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
            <div className="ProductAdmin__pagination">
              <div className="ProductAdmin__pagination-left">
                <button onClick={() => gotoPage(0)}>
                  <AiOutlineDoubleLeft />
                </button>
                <button style={{ marginLeft: ".8rem" }} onClick={previousPage}>
                  <AiOutlineLeft />
                </button>
              </div>
              <div className="ProductAdmin__pagination-center">
                <p>{`Page ${pageIndex + 1} of ${pageCount}`}</p>
                {/* <div className="ProductAdmin__pagination-center__goto">
              <p>Go to page:</p>
              <input type="number" />
            </div> */}
              </div>
              <div className="ProductAdmin__pagination-right">
                <button style={{ marginRight: ".8rem" }} onClick={nextPage}>
                  <AiOutlineRight />
                </button>
                <button onClick={() => gotoPage(pageCount - 1)}>
                  <AiOutlineDoubleRight />
                </button>
              </div>
            </div>
          </>
        )}
      </div>
      {productDetails && (
        <EditProductModal
          productDetails={productDetails}
          close={() => setProductDetails(false)}
        />
      )}
      {showAddNewProduct && (
        <AddNewProductModal close={() => setShowAddNewProduct(false)} />
      )}
      {deleteProduct && (
        <DeleteProductModal
          productDetails={deleteProduct}
          close={() => setDeleteProduct(false)}
        />
      )}
    </>
  );
};

export default ProductAdmin;
