import React, { useState } from "react";
import axios from "axios";

import "./UserDetailsCard.css";

import { AiFillSetting } from "react-icons/ai";
import { MdModeEdit, MdVerified } from "react-icons/md";
import useDataLayer from "../../helpers/StateProvider";
import { changePasswordResponse } from "../../helpers/helpers";

import BeatLoader from "react-spinners/BeatLoader";

const UserDetailsCard = () => {
  const [showLoader, setShowLoader] = useState(false);
  const [{ user }, dispatch] = useDataLayer();
  const name = `${user?.user?.firstName} ${user?.user?.lastName}`;
  const email = user?.user?.email;

  const changePassword = async () => {
    setShowLoader(true);
    const success = await changePasswordResponse(email);
    setShowLoader(false);
    success && alert("Check your mail!");
  };

  return (
    <div className="userdetailscard">
      <div className="userdetailscard__header">
        <AiFillSetting />
        <h2>My Account</h2>
      </div>
      <div className="userdetailscard__body">
        <div className="userdetailscard__body-info">
          <div className="userdetailscard__body-info__left">
            <h5>Name</h5>
            <h3>{name}</h3>
          </div>
        </div>

        <div className="userdetailscard__body-info">
          <div className="userdetailscard__body-info__left">
            <h5>Email</h5>
            <h3>{email}</h3>
          </div>
          <div
            className="userdetailscard__body-info__right"
            style={{
              backgroundColor: "white",
              color: "var(--primary-color)",
              fontWeight: "var(--font-medium)",
            }}
          >
            <MdVerified className="userdetailscard__body-info__right-icon" />
            <p>Verified</p>
          </div>
        </div>

        <div className="userdetailscard__body-info">
          <div className="userdetailscard__body-info__left">
            <h5>Password</h5>
            <h3>***************</h3>
          </div>
          <button
            disabled={showLoader}
            className="userdetailscard__body-info__right"
          >
            {showLoader ? (
              <BeatLoader size={6} color="var(--primary-color)" />
            ) : (
              <p onClick={changePassword}>Change</p>
            )}
          </button>
        </div>
      </div>
    </div>
  );
};

export default UserDetailsCard;
