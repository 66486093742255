import React, { useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import "./LearnMoreForm.css";

const validationSchema = Yup.object().shape({
  name: Yup.string().required("Name is required"),
  email: Yup.string()
    .email("Invalid email address")
    .required("Email is required"),
  company: Yup.string().required("Company is required"),
  phone: Yup.string().required("Phone number is required"),
  countryCode: Yup.string().required("Country code is required"),
  message: Yup.string().required("Message is required"),
});

const LearnMoreForm = () => {
  const [submitSuccess, setSubmitSuccess] = useState(false);

  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    try {
      const response = await fetch(
        "https://send.pageclip.co/5aZcrHjJFDwhg9ROG7eIc44VWAiyDC2s/leads-spoofsense-landing",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(values),
        }
      );

      if (response.ok) {
        setSubmitSuccess(true);
        setTimeout(() => {
          setSubmitSuccess(false);
          resetForm({
            values: {
              name: "",
              email: "",
              company: "",
              phone: "",
              countryCode: "91",
              message: "",
            },
          });
        }, 2000);
      } else {
        console.error("Error submitting form:", response.statusText);
        setSubmitSuccess(true);
        setTimeout(() => {
          setSubmitSuccess(false);
          resetForm({
            values: {
              name: "",
              email: "",
              company: "",
              phone: "",
              countryCode: "91",
              message: "",
            },
          });
        }, 2000);
      }
    } catch (error) {
      console.error("Error submitting form:", error.message);
      setSubmitSuccess(true);
      setTimeout(() => {
        setSubmitSuccess(false);
        resetForm({
          values: {
            name: "",
            email: "",
            company: "",
            phone: "",
            countryCode: "91",
            message: "",
          },
        });
      }, 2000);
    }
    setSubmitting(false);
  };

  return (
    <section className="learnmoreform">
      <div className="learnmoreform__description">
        <h2>Passive Liveness Detection combined with Deepfake Detection</h2>
        <p>
          SpoofSense enhances digital security by combining standard face
          presentation attack detection with advanced injection attack
          detection, offering a multi-layered defense against deepfakes and
          other sophisticated digital manipulations.
        </p>
      </div>
      <div className="learnmoreform__form">
        <h3>Learn More</h3>
        <Formik
          initialValues={{
            name: "",
            email: "",
            company: "",
            phone: "",
            countryCode: "91",
            message: "",
          }}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ isSubmitting, values, setFieldValue }) => (
            <Form className="learnmoreform__formik" method="post">
              <div>
                <Field
                  type="text"
                  name="name"
                  placeholder="Name"
                  className="learnmoreform__input"
                />
                <ErrorMessage
                  name="name"
                  component="div"
                  className="learnmoreform__input-error"
                />
              </div>

              <main className="learnmoreform__formik-two-input">
                <div>
                  <Field
                    type="email"
                    name="email"
                    placeholder="Email"
                    className="learnmoreform__input"
                  />
                  <ErrorMessage
                    name="email"
                    component="div"
                    className="learnmoreform__input-error"
                  />
                </div>
                <div>
                  <Field
                    type="text"
                    name="company"
                    placeholder="Company"
                    className="learnmoreform__input"
                  />
                  <ErrorMessage
                    name="company"
                    component="div"
                    className="learnmoreform__input-error"
                  />
                </div>
              </main>
              <div>
                <PhoneInput
                  country={"in"}
                  name="phone"
                  placeholder="Phone"
                  value={values.countryCode + values.phone}
                  onChange={(value, countryData) => {
                    setFieldValue(
                      "phone",
                      value.slice(countryData.dialCode.length)
                    );
                    setFieldValue("countryCode", countryData.dialCode);
                  }}
                />
                <ErrorMessage
                  name="phone"
                  component="div"
                  className="learnmoreform__input-error"
                />
              </div>
              <div>
                <Field
                  type="text"
                  name="message"
                  placeholder="Message"
                  className="learnmoreform__input"
                />
                <ErrorMessage
                  name="message"
                  component="div"
                  className="learnmoreform__input-error"
                />
              </div>
              <button
                type="submit"
                disabled={isSubmitting}
                className={submitSuccess ? "success" : ""}
              >
                {isSubmitting
                  ? "Submitting..."
                  : submitSuccess
                  ? "Thank You!"
                  : "Submit"}
              </button>
            </Form>
          )}
        </Formik>
      </div>
    </section>
  );
};

export default LearnMoreForm;
