import React, { useState } from "react";
import ReactDOM from "react-dom";

import { MdOutlineCancel } from "react-icons/md";
import { deleteUser } from "../../helpers/helpers";
import { BeatLoader } from "react-spinners";
import useDataLayer from "../../helpers/StateProvider";

import "./DeleteUserModal.css";

const DeleteUserModal = (props) => {
  const userId = props?.userDetails[0]?.value;
  const firstName = props?.userDetails[1]?.value;
  const lastName = props?.userDetails[2]?.value;

  const [isLoading, setIsLoading] = useState(false);
  const [{ user }, dispatch] = useDataLayer();

  const deleteUserHandler = async () => {
    if (user) {
      const accessToken = user?.tokens?.access?.token;
      setIsLoading(true);
      const success = await deleteUser(userId, accessToken);
      success && window.location.reload();
      setIsLoading(false);
    }
  };

  return ReactDOM.createPortal(
    <>
      <div className="deleteusermodal__overlay"></div>
      <div className="deleteusermodal">
        <MdOutlineCancel
          onClick={props?.close}
          className="deleteusermodal__close"
        />
        <h1>You're about to delete an user </h1>
        <p>{`The user ${firstName} ${lastName} will be permanentaly deleted and all data will be lost`}</p>
        <button
          onClick={deleteUserHandler}
          disabled={isLoading}
          className="deleteusermodal__save-btn"
        >
          {isLoading ? <BeatLoader size={6} color="white" /> : "Confirm"}
        </button>
      </div>
    </>,
    document.getElementById("portal")
  );
};

export default DeleteUserModal;
